import { IUploadClientAssetResponse } from "api/client"
import * as restClient from "api/restClient"

interface IDataSourceAssetResponse {
  dataSourceId: string
  id: string
  path: string
}

export const postDataSourceAsset = (id: string, file: FormData) =>
  restClient.post<IDataSourceAssetResponse>(`asset/dataSource/${id}`, file, {
    headers: { "Content-Type": "multipart/form-data" },
  })

export const postTemplateAsset = (id: string, file: FormData) =>
  restClient.post<IUploadClientAssetResponse>(`template/client/${id}/assets`, file, {
    headers: { "Content-Type": "multipart/form-data" },
  })
