import { get, put, post, destroy } from "api/restClient"

export interface IReseller {
  id: string
  name: string
  brokerageFee: number
}

export interface IResellerRequest {
  name: string
  brokerageFee?: number | null
}

export const getAll = () => get<IReseller[]>("Reseller")

export const create = (data: IResellerRequest) => post<IReseller>(`/Reseller`, data)

export const edit = (id: string, data: IResellerRequest) => put(`/Reseller/${id}`, data)

export const destroyResellerOnGroup = (groupId: string) => destroy(`/Reseller/groups/${groupId}`)

export const destroyResellerOnClient = (clientId: string) => destroy(`/Reseller/clients/${clientId}`)
