import { get, } from "api/restClient"
import { AdChannelType } from "entities/enums"

export interface IDailyChannelDelivery {
  date: string
  channel: AdChannelType
  impressions: number
  clicks: number
}

export const delivery = () => get<IDailyChannelDelivery[]>("Telemetry/delivery?days=30")
