import CircularProgress from "@mui/material/CircularProgress"
import { useIntl } from "react-intl"
import styled, { useTheme } from "styled-components"

interface ILoadingProps {
  className?: string
}

const Loading = ({ className }: ILoadingProps) => {
  const intl = useIntl()
  const theme = useTheme()
  return (
    <Loader className={className}>
      <CustomCircularProgress size={100} thickness={7} mycolor={theme.primaryColor} />
      <h1>{intl.formatMessage({ id: "public.loading" })}</h1>
    </Loader>
  )
}
export default Loading

const CustomCircularProgress = styled(CircularProgress)`
  color: ${(p) => p.mycolor} !important;
`
const Loader = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
`
