import * as restClient from "api/restClient"
import axios from "axios"
import { LinkType } from "entities/enums"

interface IMagicTokenResponse {
  success: boolean
  token: string
  refreshToken: string
  error: string
}

interface IProspectTokenRequest {
  prospectId: string
  magicToken: string
  linkType: LinkType
}

interface IDraftTokenRequest {
  draftId: string
  magicToken: string
  linkType: LinkType
}

export interface ILoginCredentials {
  email: string
  password: string
}

export const postMagicToken = (magicToken: { magicToken: string }) =>
  /* We dont want the response interceptor to handle the 401 and we dont want request interceptor to add authorization header,
  we use axios directly instead of our axiosinstace in restClient */
  axios.post<IMagicTokenResponse>(`${window.tenant.api}/Token/magic`, magicToken, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  })

export const createProspectLink = (data: IProspectTokenRequest) => restClient.post("/Token/magic/prospect", data)

export const createDraftLink = (data: IDraftTokenRequest) => restClient.post("/Token/magic/draft", data)

export const signIn = (loginCredentials: ILoginCredentials) =>
  /* We dont want the response interceptor to handle the 401 and we dont want request interceptor to add authorization header,
  we use axios directly instead of our axiosinstace in restClient */
  axios.post<IMagicTokenResponse>(`${window.tenant.api}/Token/sign-in`, loginCredentials)
