import axios from "axios"
import jwtDecode, { JwtPayload } from "jwt-decode"
import { createRefresh } from "react-auth-kit"
import { refreshTokenExpiresIn } from "utils/tokenHelpers"

const refreshApi = createRefresh({
  interval: 30, // Refreshs the token in every 30 minutes
  refreshApiCallback: async ({ authToken, refreshToken }) => {
    try {
      const { data } = await axios.post(`${window.tenant.api}/Token/refresh`, {
        refreshToken: refreshToken,
        token: authToken,
      })
      return {
        isSuccess: true,
        newAuthToken: data.token,
        newRefreshToken: data.refreshToken,
        newAuthTokenExpireIn: jwtDecode<JwtPayload>(data.token).exp!,
        newRefreshTokenExpiresIn: refreshTokenExpiresIn,
      }
    } catch (e) {
      return {
        isSuccess: false,
        newAuthToken: "",
        newRefreshToken: "",
      }
    }
  },
})

export default refreshApi
