import { useEffect, useCallback } from "react"
import { paths } from "utils/paths"
import * as API from "api"

const PreviewUrl = () => {
  const fetchData = useCallback(async () => {
    try {
      const url = window.location.href
      const parts = url.split("/")
      const id = parts[parts.length - 3]
      const channel = parts[parts.length - 1]

      const response = await API.Campaign.getPreviewUrl(id, channel)
      
      const responseData = response.data as string
      if (responseData) {
        window.location.href = responseData
      } else {
        redirectToNoAdPage()
      }
    } 
    catch (error) {
      redirectToNoAdPage()
    }
  }, [])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  const redirectToNoAdPage = () => {
    window.location.href = paths.link.noAd
  }

  return null
}

export default PreviewUrl
