import { FacebookPageAccess } from "entities/enums"
import {
  EmailType,
  IBudgetPeriodRequest,
  ICampaign,
  ICampaignList,
  IEditCampaignProlongRequest,
  ISubscriptionSettings,
  ITargeting,
} from "entities/interfaces"
import { IRenameRequest } from "entities/interfaces"
import { ICreateCampaignRequest } from "utils/campaignHelpers"
import { get, put, post, destroy } from "api/restClient"

export interface IArchiveCampaignsRequest {
  campaignId: string
}

export interface IResolveFacebookPageRequest {
  page: string
}

export interface IResolvedFacebookPage {
  id: string
  name: string
  thumbnailUrl: string
  access: FacebookPageAccess
}

export interface IEditCampaignChannelProfileRequest {
  profileId: string
}

export interface IEditCampaignDeliveryGuaranteeRequest {
  delivery: number
}

export interface IEditCampaignVideoPlacementsRequest {
  videoPlacements: Record<string, string[]>
}

export interface IPauseCampaignRequest {
  reason: string
}

export type ChannelProfile = "bidTheatre" | "facebook" | "linkedIn" | "clearChannel"

export const getAll = () => get<ICampaignList[]>("Campaign")

export const getById = (id: string) => get<ICampaign>(`Campaign/${id}`)

export const getPreviewUrl = (id: string, channel: string) => get(`campaign/${id}/preview/${channel}`)

export const create = (data: ICreateCampaignRequest) => post<ICampaign>(`Campaign`, data)

export const copyCampaign = (data: ICreateCampaignRequest) => post<ICampaign>(`Campaign`, data)

export const updateCampaignDataSource = (campaignId: string, dataSourceId: string) =>
  put(`/Campaign/${campaignId}/datasource/${dataSourceId}`)

export const updateCampaignTemplate = (campaignId: string, templateId: string) =>
  put(`/Campaign/${campaignId}/template/${templateId}`)

export const updateEmailCamapignSettings = (id: string, data: { stopEmailSend: Record<EmailType, boolean> }) =>
  put(`/Campaign/${id}/stopemailsend`, data)

export const updateCampaignSubscription = (id: string, subscription: ISubscriptionSettings) =>
  put(`/Campaign/${id}/subscription/`, subscription)

export const updateName = (id: string, data: IRenameRequest) => put(`/Campaign/${id}/name`, data)

export const updateTargeting = (id: string, data: ITargeting) => put(`/Campaign/${id}/targeting`, data)

export const destroyCampaignSubscription = (id: string) => destroy(`/Campaign/${id}/subscription/`)

export const destroyCampaignBudgetPeriod = (campaignId: string, budgetPeriodId: string) =>
  destroy(`/campaign/${campaignId}/budget/${budgetPeriodId}`)

export const createCampaignBudget = (id: string, data: IBudgetPeriodRequest) => post(`Campaign/${id}/budget`, data)

export const createProlongCampaignBudget = (id: string, data: IEditCampaignProlongRequest) =>
  post(`Prolong-Campaign/${id}/budget`, data)

export const recreateCampaign = (id: string, channel: string) => post(`Campaign/${id}/recreate/${channel}`)

export const updateCampaignBudget = (campaignId: string, budgetPeriodId: string, data: IBudgetPeriodRequest) =>
  put(`/Campaign/${campaignId}/budget/${budgetPeriodId}`, data)

export const resolveCampaignFacebookPage = (data: IResolveFacebookPageRequest) =>
  post<IResolvedFacebookPage>("/Resolve/facebook/page", data)

export const getAllCampaignsOnClient = (clientId: string) => get<ICampaign[]>(`Campaign/client/${clientId}`)

export const updateCampaignChannelProfile = (
  campaignId: string,
  channelProfile: ChannelProfile,
  data: IEditCampaignChannelProfileRequest
) => post(`/Campaign/${campaignId}/profile/${channelProfile}`, data)

export const updateCampaignDeliveryGuarantee = (campaignId: string, data: IEditCampaignDeliveryGuaranteeRequest) =>
  put(`/Campaign/${campaignId}/guarantee`, data)

export const createExpandedCampaignBudget = (id: string, data: IBudgetPeriodRequest) => post(`Campaign/${id}/budget`, data)

export const destroyCampaignDeliveryGuarantee = (campaignId: string) => destroy(`/Campaign/${campaignId}/guarantee`)

export const updateCampaignVideoPlacements = (campaignId: string, data: IEditCampaignVideoPlacementsRequest) =>
  put(`/Campaign/${campaignId}/placements/video`, data.videoPlacements)

export const pause = (id: string, data: IPauseCampaignRequest) => post(`/Campaign/${id}/pause`, data)

export const unpause = (id: string) => post(`/Campaign/${id}/unpause`)

export const archiveCampaign = (data: IArchiveCampaignsRequest) => post(`/Campaign/archive`, data)

export const integratePinMeTo = (campaignId: string) => put(`/Campaign/${campaignId}/pinmeto`)
