import styled from "styled-components"

interface IErrorMessageProps {
  className?: string
  children: string
}

const ErrorMessage = ({ children, className = "" }: IErrorMessageProps) => (
  <ErrorSpan className={`hasError ${className}`}>{children}</ErrorSpan>
)

export default ErrorMessage

const ErrorSpan = styled.span`
  display: block;
  color: #ff4757;
  margin-top: 1em;
`
