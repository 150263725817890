import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios"
import { paths } from "utils/paths"
import { destroyToken, getCurrentTokens, setTokenAndRefreshToken } from "utils/tokenHelpers"
import { IApplicationTenant } from "../../window"

initializeTenant()

let baseUrl: string;
let axiosRequestConfig: AxiosRequestConfig;
let axiosInstance: AxiosInstance;
let refreshRequest: Promise<any> | null = null

function getTenantSettings(host: string, client: string|null) {
  try {   
    const request = new XMLHttpRequest();
    const apiHost = `https://api.${host}`;
    const url = `${apiHost}/tenant/settings?host=${host}&client=${client}`;
    request.open('GET', url, false);
    request.send();
    if (request.status === 200) {
      return JSON.parse(request.responseText);
    } else {
      const errorMessage = `Failed to fetch data from ${apiHost}. Status: ${request.status}`;
      alert(errorMessage);
    }
  } catch (error) {
    const baseURL = process.env.REACT_APP_DEFAULT_API_DOMAIN || "";
    return fallbackRequest(baseURL,host, client);
  }
};

function initializeTenant() {
  const host = process.env.REACT_APP_HOST
    ? process.env.REACT_APP_HOST
    : (document && document.location && document.location.hostname) || "unknown"

    const pathname = window.location.pathname
    const pathSegments = pathname.split('/').filter(segment => segment !== '');
    const client = pathname.includes('/draft/') || pathname.includes('/prospect/') || pathname.includes('/integration/') ? pathSegments[0] : "";

    const data = getTenantSettings(host, client);
    window.tenant = {
      id: data.id,
      api: data.api,
      cdn: data.cdn,
      product: { name: data.product.name, link: data.product.link },
      color: data.color,
      language: data.language,
      logoUri: data.tenantLogoUri,
      supportEmail: data.supportEmail
    }
    if (process.env.LANGUAGE) {
      window.tenant.language = process.env.LANGUAGE
    }
    var favicon = window.document.createElement("link")
    favicon.type = "image/x-icon"
    favicon.rel = "icon"
    favicon.href = `${window.tenant.logoUri}/favicon.png`
    window.document.head.appendChild(favicon)
    intialize()
}

function fallbackRequest(baseURL: string, host: string, client: string | null){
  const request = new XMLHttpRequest();   
    const url = `${baseURL}/tenant/settings?host=${host}&client=${client}`;
    request.open('GET', url, false);
    request.send();
    if (request.status === 200) {
      return JSON.parse(request.responseText);
    } else {
      const errorMessage = `Failed to fetch data from ${baseURL}. Status: ${request.status}`;
      alert(errorMessage);
    }    
}

async function intialize() {
  baseUrl = window.tenant.api;
  axiosRequestConfig = {
    baseURL: baseUrl,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  }
  axiosInstance = axios.create(axiosRequestConfig)

  axiosInstance.interceptors.request.use(
    (config) => {
      const token = localStorage.getItem("token")
      if (!config.headers) {
        config.headers = {}
      }
      config.headers.Authorization = "Bearer " + token
      return config
    },
    (error) => {
      return Promise.reject(error)
    }
  )
                                                                                                        
  axiosInstance.interceptors.response.use(
    //valid response return immidately
    (response) => response,
    async (error) => {
      if (!error.response) {
        // If server is down or no internet connection return error immediately
        // example net::ERR_CONNECTION_REFUSED
        return Promise.reject(error)
      }
      if (error.response.status === 401) {
        if (!refreshRequest) {
          // works because it returns a promise and a promise is cacheable
          refreshRequest = sendRefreshRequest(getCurrentTokens())
        }
        const { data } = await refreshRequest
        setTokenAndRefreshToken(data.token, data.refreshToken)
        axiosInstance.defaults.headers.common["Authorization"] = "Bearer " + data.token
        refreshRequest = null
        if (data) {
          return axiosInstance(error.config)
        }
      }
  
      return Promise.reject(error)
    }
  )
}

interface ITokenRequest {
  token: string | null
  refreshToken: string | null
}

const sendRefreshRequest = async ({ token, refreshToken }: ITokenRequest) => {
  try {
    return await axios.post(`${window.tenant.api}/Token/refresh`, { token, refreshToken })
  } catch (error) {
    return Promise.reject(error)
  }
}

function handleUnauthorized() {
  destroyToken()
  window.location.href = paths.authentication.unauthorized
}

function handleForbidden() {
  window.location.href = paths.authentication.permissiondenied
}

function handleError(error: any) {
  if (error.response?.status === 401) {
    handleUnauthorized()
  }
  if (error.response.status === 403) {
    handleForbidden()
  }
  if (error.response.status === 400) {
    return error.response
  }

  return error
}

export async function get<T>(resourceEndpointPath: string, config?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
  try {
    return await axiosInstance.get(resourceEndpointPath, config)
  } catch (error) {
    throw handleError(error)
  }
}

export async function post<T>(resourceEndpointPath: string, data?: any, config?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
  try {
    return await axiosInstance.post(resourceEndpointPath, data, config)
  } catch (error) {
    return handleError(error)
  }
}

export async function put<T>(resourceEndpointPath: string, data?: any): Promise<AxiosResponse<T>> {
  try {
    return await axiosInstance.put(resourceEndpointPath, data)
  } catch (error) {
    return handleError(error)
  }
}

export async function destroy<T>(resourceEndpointPath: string): Promise<AxiosResponse<T>> {
  try {
    return await axiosInstance.delete(resourceEndpointPath)
  } catch (error) {
    throw handleError(error)
  }
}
