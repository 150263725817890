import { Role } from "entities/enums"
import { ICurrentUser } from "entities/interfaces"
import jwtDecode, { JwtPayload } from "jwt-decode"

const IDENTITY_CLAIM_ID_KEY = "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier"
const IDENTITY_CLAIM_EMAIL_KEY = "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress"
const IDENTITY_CLAIM_NAME_KEY = "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name"
const IDENTITY_CLAIM_ROLE_KEY = "http://schemas.microsoft.com/ws/2008/06/identity/claims/role"
const IDENTITY_CLAIM_TENANT_KEY = "Tenant"

// temp solution until we get more roles in BE
const trustedUsers: string[] = [
  "emil.pettersson@imseb.com", // Emil Pettersson
  "philip.olsson@bifirm.com", // Philip Olsson
  "johan.nyman@bifirm.com", // Johan Nyman
  "anders.waglund@bifirm.com", // Anders Wåglund
  "daffy.duck@acme.test", // Daffy Duck
  "ying.han@bifirm.com", // Ying Han
  "aw@bifirm.com", // Andreas Wallenberg
  "michael.fallstrom@bifirm.com", // Michael Fällström
  "johan.lindsten@bifirm.com", // Johan Lindsten
  "mf@bifirm.com", // Michael Fällström
  "mergime.sulejmani@bifirm.com", // Meg
  "johan.linderholm@bifirm.com", // Johan Linderholm
  "amolw@heaptrace.com", // Amol
  "lisa.backlund@spiderads.io", // Lisa Backlund
]
export const isTrustedUser = (user: ICurrentUser | null): boolean => (user ? trustedUsers.includes(user.email) : false)

/* User helpers */
export const isAdmin = (user: ICurrentUser | null): boolean => (user ? user.role === Role.Admin : false)

export const isProspectUser = (user: ICurrentUser | null): boolean => (user ? user.role === Role.ProspectUser : false)

export const isTenantManager = (user: ICurrentUser | null): boolean => (user ? user.role === Role.TenantManager : false)

export const isUser = (user: ICurrentUser | null): boolean => (user ? user.role === Role.User : false)

export const isSpiderAdsProduct = (): boolean => {
  switch (window.tenant.product.name) {
    case "SpiderAds":
    case "Staging":
    case "Local-SpiderAds":
      return true
    default:
      return false
  }
}
export const decodeTokenToUser = (token: string) => {
  const decodeToken = jwtDecode<JwtPayload>(token)
  const user: ICurrentUser = {
    id: decodeToken[IDENTITY_CLAIM_ID_KEY],
    email: decodeToken[IDENTITY_CLAIM_EMAIL_KEY],
    name: decodeToken[IDENTITY_CLAIM_NAME_KEY],
    role: decodeToken[IDENTITY_CLAIM_ROLE_KEY],
    tenant: decodeToken[IDENTITY_CLAIM_TENANT_KEY],
  }
  return user
}
