/* eslint-disable @typescript-eslint/no-unused-vars */
import { observable, action, makeObservable } from "mobx"
import { DataSourceStore } from "./DataSourceStore"
import { OrderStore } from "./OrderStore"
import { ICurrentUser } from "../entities/interfaces"
import { decodeTokenToUser } from "utils/user_tenant_helpers"
/* eslint-enable @typescript-eslint/no-unused-vars */

export class RootStore {
  @observable public dataSourceStore: DataSourceStore
  @observable public orderStore: OrderStore
  @observable public currentUser: ICurrentUser
  constructor() {
    makeObservable(this)
    this.dataSourceStore = new DataSourceStore(this)
    this.orderStore = new OrderStore(this)
    this.setCurrentUser()
  }

  @action public async setCurrentUser() {
    const token = localStorage.getItem("token")

    if (token) {
      const { id, email, name, role } = decodeTokenToUser(token)

      this.currentUser = { id, email, name, role }
    }
  }
}
