import {
  IDataSourceFailListResponse,
  IDataSourceListResponse,
  IDataSourceProviderHistoryResponse,
  IRenameRequest,
} from "entities/interfaces"
import { IDataSource, IDataSourceData, IDataSourceProvider, IDataSourceProviderEdit } from "entities/interfaces"
import { get, put, post, destroy } from "api/restClient"

export interface ICreateDataSourceRequest {
  name: string
  clientId: string
  providers: IDataSourceProvider[] | null
}

export interface ICloneDataSourceRequest {
  destinationClientId: string
  name: string
}
export interface ICronDataSourceRequest {
  crawlDelay?: string
  crawlCron?: string
  customCronExpression?: string
  customInterval?: {
    hours?: number
    minutes?: number
    daysOfWeek?: string[]
  }
}

export interface IDataSourceRequest {
  crawlCron?: string
}

export const getAllByClientId = (clientId: string) => get<IDataSource[]>(`/DataSource/client/${clientId}`)

export const getListByClientId = (clientId: string) => get<IDataSourceListResponse[]>(`/DataSource/client/${clientId}`)

export const getById = (id: string) => get<IDataSource>(`DataSource/${id}`)

export const getFailures = () => get<IDataSourceFailListResponse[]>(`/DataSource/failed`)

export const getHistory = (id: string) => get<IDataSourceProviderHistoryResponse[]>(`DataSource/${id}/history`)

export const create = (data: ICreateDataSourceRequest) => post<{ id: string }>("DataSource", data)

export const updateDataSourceData = (id: string, data: IDataSourceData) => put(`DataSource/${id}/data`, data)

export const updateDataSourceProviders = (id: string, data: string | IDataSourceProviderEdit[]) =>
  put(`/DataSource/${id}/providers`, data)

export const activate = (id: string) => post(`/DataSource/${id}/activate`)

export const deactivate = (id: string) => post(`/DataSource/${id}/deactivate`)

export const remove = (id: string) => destroy(`/DataSource/${id}`)

export const updateName = (id: string, data: IRenameRequest) => put(`/DataSource/${id}/name`, data)

export const editCrawlSpeed = (id: string, data: { crawlDelay: string }) => put(`/DataSource/${id}/crawldelay`, data)

export const clone = (dataSourceId: string, data: ICloneDataSourceRequest) => post(`/DataSource/${dataSourceId}/clone`, data)

export const editCustomCrawlSpeed = (id: string, data: IDataSourceRequest) => put(`/DataSource/${id}/customcrawl`, data)
