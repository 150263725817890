import styled from "styled-components"

interface IHintProps {
  className?: string
  children: string | JSX.Element | JSX.Element[]
  fontSize?: string
  margin?: string
  warning?: boolean
}

const Hint = ({ children, className, fontSize = "12px", margin = " 1em 0 0 0", warning = false }: IHintProps) => (
  <HintSpan fontsize={fontSize} className={className} margin={margin} warning={warning}>
    {children}
  </HintSpan>
)

export default Hint

const HintSpan = styled.span`
  display: block;
  font-size: ${(p) => p.fontsize};
  color: ${(p) => (p.warning ? p.theme.orange : p.theme.muted)};
  margin: ${(p) => p.margin};
`
