import { ImageTextOption, LandingPageSetting, ReferenceType } from "entities/enums"
import {
  ICampaignModel,
  IItems,
  IOrderChannels,
  IProspectCampaignRequest,
  IProspectConfiguration,
  IProspectSourceViewModel,
  IProspectViewModel,
} from "entities/interfaces"
import { addHoursClean, handleSetDefaultEndDate } from "utils/dateHelpers"
/* eslint-disable @typescript-eslint/no-unused-vars */
import { action, observable, makeObservable } from "mobx"
/* eslint-enable @typescript-eslint/no-unused-vars */

enum OrderImageKey {
  Description = "description",
  Title = "title",
  PhoneNumber = "phoneNumber",
}

const handleImageOption = (overrideText: string, orderImages: IItems[], orderImageKey: OrderImageKey): ImageTextOption => {
  const firstObjectValue = orderImages[0]?.[orderImageKey]
  const otherObjectsNotHaveFields = orderImages
    .slice(1)
    .every((obj) => obj?.[orderImageKey] === undefined || obj?.[orderImageKey] === "")

  if (firstObjectValue !== undefined && otherObjectsNotHaveFields) {
    orderImages.slice(1).forEach((image) => {
      if (!image[orderImageKey]) {
        if (orderImageKey === OrderImageKey.PhoneNumber && firstObjectValue === null) {
          image[orderImageKey] = null
        } else {
          image[orderImageKey] = overrideText || firstObjectValue || ""
        }
      }
    })
    return ImageTextOption.Same
  } else {
    const allHaveSameValue = orderImages.every((image) => image?.[orderImageKey] === firstObjectValue)
    return allHaveSameValue ? ImageTextOption.Same : ImageTextOption.Unique
  }
}

const handleLandingPage = (source: IProspectSourceViewModel, orderImages: IItems[]): LandingPageSetting => {
  if (source.landingPageSetting === LandingPageSetting.Uneditable) {
    return LandingPageSetting.Uneditable
  }
  if (!orderImages?.length) {
    return source.landingPageSetting
  } else {
    const firstValue = orderImages[0].landingPage
    const uniqueValues = orderImages.some((image) => image.landingPage !== firstValue)
    return uniqueValues ? LandingPageSetting.Unique : source.landingPageSetting
  }
}

export class ProspectCampaignRequest implements IProspectCampaignRequest {
  @observable public id: string
  @observable public campaignData: ICampaignModel
  @observable public channels: IOrderChannels
  @observable public start: Date
  @observable public end: Date
  @observable public reference: string
  @observable public customReferences: Record<string, string>
  @observable public reportRecipients: string[]
  @observable public campaignManagers: string[]
  @observable public linkedInAmount: number
  @observable public amount: number
  @observable public configuration: IProspectConfiguration
  @observable public startAsap: boolean
  @observable public source: IProspectSourceViewModel
  @observable public imageTitleOption: ImageTextOption
  @observable public imageDescriptionOption: ImageTextOption
  @observable public imagePhoneNumberOption: ImageTextOption
  @observable public isSnapshot: boolean

  constructor(prospectCampaignRequest: IProspectCampaignRequest & IProspectViewModel) {
    makeObservable(this)
    this.fromJson(prospectCampaignRequest)
  }

  @action public fromJson(prospectCampaignRequest: IProspectCampaignRequest & IProspectViewModel): void {
    const firstCampaign = prospectCampaignRequest.campaigns[0]
    const endDate = new Date(firstCampaign.budgets.slice(-1)[0].end)
    this.id = prospectCampaignRequest.id
    this.campaignData = firstCampaign

    this.configuration = prospectCampaignRequest.configuration
    this.startAsap = true
    this.isSnapshot = prospectCampaignRequest.isSnapshot
    let currentStartDate = new Date(firstCampaign.budgets.slice(-1)[0].start)
    const startDate = currentStartDate >= new Date() ? currentStartDate : new Date()
    this.start = addHoursClean(startDate, 1)
    this.end = handleSetDefaultEndDate(endDate, prospectCampaignRequest.source.endDateSetting)
    const isReferenceTypeNone = this.configuration.referenceSettings.type === ReferenceType.None
    const referenceValue = this.configuration.referenceSettings.value || ""

    this.reference = firstCampaign.settings.reference ?? (isReferenceTypeNone ? "" : referenceValue)
    const keys = Object.keys(this.configuration.customReferenceFields)
    this.customReferences = initializeCustomReferencesFromKeys(keys)
    this.reportRecipients = firstCampaign.reportRecipients
    this.campaignManagers = firstCampaign.campaignManagers || []
    this.linkedInAmount = firstCampaign.budgets[0].linkedInAmount
    this.amount = prospectCampaignRequest.configuration.pricingLevels.find(
      (x) => x === firstCampaign.budgets.slice(-1)[0].amount.value
    )
      ? prospectCampaignRequest.campaigns[0].budgets.slice(-1)[0].amount.value
      : prospectCampaignRequest.configuration.pricingLevels[1]
    this.source = prospectCampaignRequest.source
    this.imageTitleOption = handleImageOption(
      this.campaignData.settings.overrideImageTitles,
      this.campaignData.images,
      OrderImageKey.Title
    )
    if (this.imageTitleOption === ImageTextOption.Same) {
      this.campaignData.settings.overrideImageTitles =
        this.campaignData.settings.overrideImageTitles || this.campaignData.images[0]?.[OrderImageKey.Title] || ""
    }

    this.imageDescriptionOption = handleImageOption(
      this.campaignData.settings.overrideImageDescriptions,
      this.campaignData.images,
      OrderImageKey.Description
    )

    if (this.imageDescriptionOption === ImageTextOption.Same) {
      this.campaignData.settings.overrideImageDescriptions =
        this.campaignData.settings.overrideImageDescriptions || this.campaignData.images[0]?.[OrderImageKey.Description] || ""
    }

    this.imagePhoneNumberOption = handleImageOption(
      this.campaignData.settings.overrideImagePhoneNumbers,
      this.campaignData.images,
      OrderImageKey.PhoneNumber
    )

    if (this.imagePhoneNumberOption === ImageTextOption.Same) {
      this.campaignData.settings.overrideImagePhoneNumbers = this.campaignData.settings.overrideImagePhoneNumbers || ""
    }

    function initializeCustomReferencesFromKeys(keys: string[]): Record<string, string> {
      const {
        settings: { customReferences },
      } = firstCampaign

      return keys.reduce((acc, key) => {
        acc[key] = customReferences[key] || ""
        return acc
      }, {} as Record<string, string>)
    }

    this.campaignData.settings.phoneNumberSetting = this.campaignData.settings.phoneNumberSetting || false
    this.source.landingPageSetting = handleLandingPage(this.source, this.campaignData.images)
  }
}
