/* eslint-disable @typescript-eslint/no-unused-vars */
import { action, observable, computed, makeObservable } from "mobx"
import { IClient, IFacebookData, IItems } from "entities/interfaces"
import * as API from "api"
import { ProspectCampaignRequest } from "entities/models/Prospect"
import { calculateImpressionsCpm } from "utils/calculations"
import { CallToActionType } from "entities/enums"
import { validateLocationsOverlap } from "utils/locationHelpers"
import { AdProspectType, IOrderDataStore, OrderStepView, OrderStore } from "./OrderStore"
import { prepareFacebookName } from "utils/url"
import { ITenantMediaResponse } from "api/tenant"
import { ICommitBudgetRequest, ISetBudgetRequest } from "api/prospect"
import axios from "axios"
import { getRandomImagesByTags, getRandomItemsFromArray } from "utils/collectionHelpers"
import toast from "react-hot-toast"
/* eslint-enable @typescript-eslint/no-unused-vars */

var uniqBy = require("lodash.uniqby")

const messagePrefix = "sa-message:"

function postMessage(jsonData: any) {
  window.parent.postMessage(`${messagePrefix}${JSON.stringify(jsonData)}`, "*")
}

export class OrderDraftStore implements IOrderDataStore {
  @observable public client: IClient
  @observable public prospect: ProspectCampaignRequest
  @observable public facebookData: IFacebookData
  @observable public orderStore: OrderStore
  @observable public validatingLocations: boolean

  constructor(orderStore: OrderStore) {
    makeObservable(this)
    this.orderStore = orderStore
  }
  @action public async init(
    slug: string,
    draftId: string,
    search: string,
    adProspectType: AdProspectType,
    startOnLastStep: boolean
  ) {
    try {
      const clientResponse = await API.Client.getBySlug(slug)
      this.client = clientResponse.data
      const draftResponse = await API.Draft.getById(draftId)
      this.prospect = new ProspectCampaignRequest(draftResponse.data)

      const facebookPageName = prepareFacebookName(draftResponse.data.campaigns[0].settings.facebookPage)

      this.orderStore.alreadyOrdered = !!draftResponse.data.campaigns[0].budgets[0].orderedAt
      const prospectMedialibrary = await API.Tenant.getAllTenantMediaOnClient(this.client.id)
      const mediaLibrary = prospectMedialibrary.data

      this.orderStore.selectedTags = ((await API.Draft.getRecommendedTags(draftId)).data ?? { tags: [] }).tags

      mediaLibrary.map((media: ITenantMediaResponse) =>
        this.prospect.configuration.availableImages.push({
          title: "",
          description: "",
          phoneNumber: null,
          url: media.path,
          tags: media.tags,
          hasVideoUrl: Boolean(media.videoUrl),
          category: media.category,
        })
      )

      const availableImages = this.prospect.configuration.availableImages
      this.prospect.configuration.allAvailableImages = availableImages

      const campaignImages = this.prospect.campaignData.images

      this.orderStore.tenantTags = this.client.tenant?.id
        ? (await API.Tenant.getLocaleTags(this.client.tenant.id, this.client.id, this.client.languageCode)).data
        : []
      if (campaignImages.length <= 1 && availableImages.length) {
        const filteredAvailable = availableImages.filter(({ url }: IItems) => !campaignImages.find((x) => x.url === url))
        const randomImageItems = getRandomImagesByTags(
          filteredAvailable,
          this.client.languageCode,
          !this.orderStore.selectedTags.length
            ? getRandomItemsFromArray(this.orderStore.tenantTags, 2)
            : this.orderStore.selectedTags,
          campaignImages.length === 1 ? 2 : 3
        )

        const images = [...campaignImages, ...randomImageItems]
        this.prospect.campaignData.images = uniqBy(images, "url")
      }
      const allFacebookResponse = await Promise.all([
        API.Campaign.resolveCampaignFacebookPage({ page: facebookPageName }),
        API.Template.getById(this.prospect.source.templateId),
      ])
      this.facebookData = allFacebookResponse[0].data
      this.facebookData.callToAction = allFacebookResponse[1].data.channels.facebook?.callToAction || CallToActionType.NoButton
      this.prospect.channels = allFacebookResponse[1].data.channels
    } catch (error: unknown) {
      if (axios.isAxiosError(error)) {
        if (!error.response || error.response?.status >= 500) throw new Error("Network Error")
      }

      const errorMessage = `${JSON.stringify(error, Object.getOwnPropertyNames(error))}\r\n${error}`
      toast.error(`${errorMessage}`, { duration: Infinity })
    }

    await this.handleValidateDraftStartStep(startOnLastStep)
  }

  @action public async handleValidateDraftStartStep(startOnLastStep: boolean) {
    this.orderStore.validateLandingPages()
    if (!this.prospect.campaignData.images?.length || !this.orderStore.landingPagesValid) {
      this.orderStore.currentOrderStep = OrderStepView.proofStep
    } else {
      //validate targeting and start at the step if invalid
      const validLocations = await validateLocationsOverlap(this.prospect.campaignData.targeting.locations)
      if (!validLocations && startOnLastStep) {
        this.orderStore.currentOrderStep = OrderStepView.targetingStep
        this.orderStore.locationsValid = validLocations
      } else {
        this.orderStore.currentOrderStep = startOnLastStep ? OrderStepView.budgetStep : OrderStepView.proofStep
      }
    }
  }

  @action public async onPutProof() {
    try {
      await API.Draft.updateImages(this.prospect.id, this.prospect.campaignData.images)

      const settingsRequest = API.Prospect.mapCampaignDataSettingsToProspectSettingsRequest(
        this.prospect.campaignData,
        this.prospect.imageTitleOption,
        this.prospect.imageDescriptionOption,
        this.prospect.imagePhoneNumberOption,
        this.prospect.campaignData.settings.overrideImageTitles,
        this.prospect.campaignData.settings.overrideImageDescriptions,
        this.prospect.campaignData.settings.overrideImagePhoneNumbers
      )
      await API.Draft.updateSettings(this.prospect.id, settingsRequest)
    } catch (error) {
      this.orderStore.currentOrderStep = OrderStepView.proofStep
      //todo: maybe we should give more error back? what happened
      this.orderStore.couldNotOrderProspect = true
    }
  }

  @action public async onPutTargeting() {
    try {
      await API.Draft.updateTargeting(this.prospect.id, this.prospect.campaignData.targeting)
    } catch (error) {
      //todo: maybe we should give more error back? what happened
      this.orderStore.currentOrderStep = OrderStepView.targetingStep
      this.orderStore.couldNotOrderProspect = true
    }
  }

  @action public async onBuyOrder() {
    this.orderStore.loading = true
    try {
      const budgetRequest: ISetBudgetRequest = {
        amount: this.prospect.amount,
        start: this.prospect.start,
        end: this.prospect.end,
        linkedInAmount: this.prospect.linkedInAmount,
      }
      await API.Draft.updateReportRecipients(this.prospect.id, this.prospect.reportRecipients)
      if (this.prospect.source.canAddCampaignManagers) {
        await API.Draft.updateCampaignManagers(this.prospect.id, this.prospect.campaignManagers)
      }

      await API.Draft.updateBudget(this.prospect.id, budgetRequest)

      const commitBudgetRequest: ICommitBudgetRequest = {
        reference: this.prospect.reference,
        customReferences: this.prospect.customReferences,
      }

      const commitBudgetResponse = await API.Draft.commitBudget(this.prospect.id, commitBudgetRequest)

      postMessage(commitBudgetResponse.data)

      this.orderStore.prospectOrderDone = true
    } catch (error) {
      this.orderStore.couldNotOrderProspect = true
      this.orderStore.loading = false
    }
    this.orderStore.loading = false
  }

  @action public async validateLocationsInitial() {
    const locations = this.prospect.campaignData.targeting.locations
    if (locations.length) {
      this.validatingLocations = true
      this.orderStore.locationsValid = await validateLocationsOverlap(locations)
      this.validatingLocations = false
    }
  }

  /* VALIDATION FUNCTIONS END */

  @computed get targetedImpressions(): number {
    return this.prospect ? calculateImpressionsCpm(this.prospect.amount, this.prospect.configuration.currency.cpm) : 0
  }

  @computed get totalBudget(): number {
    return this.prospect ? Number(this.prospect.amount) + Number(this.prospect.linkedInAmount) : 0
  }
}
