import { Paper } from "@mui/material"

import styled from "styled-components"
import { ITheme } from "../../entities/interfaces"
import FaIcon from "./FaIcon"
import Odometer from "./Odometer"
import DonutChart from "./DonutChart"

interface IDonutChartPaperProps {
  value: number | null
  text: string
  icon: any
  theme: ITheme
}

const DonutChartPaper = ({ icon, value, text, theme }: IDonutChartPaperProps) => (
  <CustomPaper>
    <IconWrapper>
      <FaIcon icon={icon} />
    </IconWrapper>

    <StatsPercentage theme={theme}>
      <Odometer value={value} />
      <Percentage>%</Percentage>
    </StatsPercentage>

    <DonutChart value={value} />
    <CustomText>{text}</CustomText>
  </CustomPaper>
)
export default DonutChartPaper

const CustomPaper = styled(Paper)`
  text-align: center;
  padding: 2em 1.5em;
  position: relative;
  height: 150px;
  overflow: hidden;
  margin-bottom: 1em;

  @media only screen and (min-width: 768px) {
    :not(:last-child) {
      margin-right: 0.4em;
    }
    width: 30%;
    display: inline-block;
  }
`
const IconWrapper = styled.div`
  position: absolute;
  font-size: 30px;
  top: 35%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  color: hsla(210, 17%, 69%, 1);
`

const Percentage = styled.span`
  font-size: 18px;
`

const CustomText = styled.p`
  font-size: 15px;
  font-weight: lighter;
`

const StatsPercentage = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  color: ${(p) => p.theme.primaryColor};
  font-weight: bold;
  font-size: 20px;
`
