import styled from "styled-components"
import Snackbar from "@mui/material/Snackbar"
import { SnackbarContent } from "@mui/material"
import FaIcon from "./FaIcon"
import { SnackbarType } from "entities/enums"

interface ISnackbarMessageProps {
  open: boolean
  message: string
  snackbarType: SnackbarType
}

const SnackbarMessage = ({ message, open, snackbarType }: ISnackbarMessageProps) => (
  <Snackbar
    open={open}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
  >
    <SnackbarMessageContent
      snackbarType={snackbarType}
      message={
        <span>
          <SnackIcon prefix="fa" icon="check" />
          {message}
        </span>
      }
    />
  </Snackbar>
)
export default SnackbarMessage

const SnackbarMessageContent = styled(SnackbarContent)`
  ${(p) => handleColorType(p.snackbarType, p)};
`

const SnackIcon = styled(FaIcon)`
  margin-right: ${(p) => p.theme.spacingSmall};
`

const handleColorType = (snackbarType: SnackbarType, p: any) => {
  switch (snackbarType) {
    case SnackbarType.Success:
      return `background-color: ${p.theme.green} !important;`
    case SnackbarType.Error:
      return `background-color: ${p.theme.red} !important;`
    default:
      return `background-color: #000 !important;`
  }
}
