import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import styled from "styled-components"

interface IFaIconProps {
  icon: string
  margin?: string
  prefix?: string
  className?: string
  fontSize?: string
}

const FaIcon = ({ icon, prefix = "fas", className, margin, fontSize = "inherit" }: IFaIconProps) => (
  <CommonIcon margin={margin} fontSize={fontSize} className={className} icon={[prefix, icon]} />
)

export default FaIcon

const CommonIcon = styled(FontAwesomeIcon)`
  min-width: 1.5em;
  margin: ${(p) => p.margin};
  font-size: ${(p) => p.fontSize};
`
