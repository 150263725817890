import { useSpring, animated, config } from "react-spring"
import { useIntl } from "react-intl"

interface IOdometerProps {
  value: number | null
}

const Odometer = ({ value = 0 }: IOdometerProps) => {
  const { formatNumber } = useIntl()

  const prop = useSpring({
    val: value,
    from: { val: 0 },
    config: { ...config.gentle, duration: 2500, delay: 150, easing: (x) => Math.sin((x * Math.PI) / 2) },
  })
  const isDecimals = (value: number | null) => {
    if (value && value % 1 !== 0) {
      return prop.val.to((v) => formatNumber(+v.toFixed(2)))
    } else {
      return prop.val.to((v) => formatNumber(Math.floor(v)))
    }
  }
  return <animated.span>{isDecimals(value)}</animated.span>
}

export default Odometer
