/* eslint-disable @typescript-eslint/no-unused-vars */
import { action, computed, observable, makeObservable, toJS } from "mobx"
import { RootStore } from "mobxStores"
import { OrderProspectStore } from "./OrderProspectStore"
import { OrderDraftStore } from "./OrderDraftStore"
import { OrderDemoStore } from "./OrderDemoStore"
import { addHoursClean } from "utils/dateHelpers"
import { geocodeByPlaceId, getLatLng } from "react-places-autocomplete"
import { RADIUS_PER_GEOGRAPHIC_TYPES, validateLocationsOverlap } from "utils/locationHelpers"
import { ProspectCampaignRequest } from "entities/models/Prospect"
import { IClient, IFacebookData, ILocation } from "entities/interfaces"
import toast from "react-hot-toast"
import { isSpecialAdCategory } from "utils/campaignHelpers"
import { calcDailyImpressions, validateDailyImpressions } from "utils/calculations"
import { differenceInHours } from "date-fns"
import { ImageTextOption, LandingPageSetting, PhoneNumberSetting } from "entities/enums"
import { isValidHttpUrl } from "utils/validators"
import { hasDisplayShare } from "utils/prospectHelpers"
/* eslint-enable @typescript-eslint/no-unused-vars */

export enum OrderStepView {
  proofStep = 0,
  targetingStep,
  budgetStep,
}
export enum AdProspectType {
  newProspect = 0,
  editProspect,
  extendProspect,
  humanResourcesProspectDemo,
  realEstateProspectDemo,
  carProspectDemo,
  integrationDraft,
  dutchDemo,
  finnishDemo,
}

export interface IOrderDataStore {
  client: IClient
  prospect: ProspectCampaignRequest
  facebookData: IFacebookData
  orderStore: OrderStore
  validatingLocations: boolean
  targetedImpressions: number
  totalBudget: number
  init: (slug: string, dataId: string, search: string, adProspectType: AdProspectType, startOnLastStep: boolean) => Promise<void>
  onPutProof: () => Promise<void>
  onPutTargeting: () => Promise<void>
  onBuyOrder: () => Promise<void>
  validateLocationsInitial: () => Promise<void>
}

export class OrderStore {
  @observable public locationsValid: boolean = true
  @observable public landingPagesValid: boolean = true
  @observable public rootStore: RootStore
  @observable public loading: boolean = true
  @observable public saving: boolean = false
  @observable public currentOrderStep: OrderStepView = OrderStepView.proofStep
  @observable public referenceRequired: boolean = false
  @observable public customReferenceRequired: boolean = false
  @observable public prospectOrderDone: boolean = false
  @observable public couldNotOrderProspect: boolean = false
  @observable public errorMessage: string = ""
  @observable public alreadyOrdered: boolean = false
  @observable public address: string = ""
  @observable public isSpecialAdCategory: boolean = false

  @observable public adProspectType: AdProspectType
  @observable public validatingLocations: boolean
  @observable public selectedTags: string[] = []
  @observable public tenantTags: string[] = []

  @observable public dataStore: OrderProspectStore | OrderDraftStore | OrderDemoStore

  constructor(rootStore: RootStore) {
    makeObservable(this)
    this.rootStore = rootStore
  }

  @action public async init(slug: string, id: string, search: string, adProspectType: AdProspectType, startOnLastStep: boolean) {
    this.adProspectType = adProspectType
    try {
      switch (adProspectType) {
        case AdProspectType.integrationDraft:
          this.dataStore = new OrderDraftStore(this)
          await this.dataStore.init(slug, id, search, adProspectType, startOnLastStep)
          this.isSpecialAdCategory = isSpecialAdCategory(this.dataStore.prospect.configuration.category)
          break
        case AdProspectType.extendProspect:
        case AdProspectType.newProspect:
        case AdProspectType.editProspect:
          this.dataStore = new OrderProspectStore(this)
          await this.dataStore.init(slug, id, search, adProspectType, startOnLastStep)
          this.isSpecialAdCategory = isSpecialAdCategory(this.dataStore.prospect.configuration.category)
          break
        case AdProspectType.carProspectDemo:
        case AdProspectType.humanResourcesProspectDemo:
        case AdProspectType.realEstateProspectDemo:
        case AdProspectType.dutchDemo:
        case AdProspectType.finnishDemo:
          this.dataStore = new OrderDemoStore(this)
          await this.dataStore.init(slug, id, search, adProspectType, startOnLastStep)
          this.isSpecialAdCategory = isSpecialAdCategory(this.dataStore.prospect.configuration.category)
          break
        default:
          throw new Error("Not valid AdProspectType")
      }
    } catch (error) {
      toast.error(`${error}`, { duration: Infinity })
    }

    this.loading = false
  }

  @action public changeCurrentOrderStep(nextStep: OrderStepView) {
    let nextPage = nextStep
    if (this.currentOrderStep === OrderStepView.proofStep && nextStep === OrderStepView.targetingStep) {
      this.validateLandingPages()
      if (this.landingPagesValid) {
        this.dataStore.onPutProof()
      } else {
        nextPage = OrderStepView.proofStep
      }
    } else if (this.currentOrderStep === OrderStepView.targetingStep && nextStep === OrderStepView.budgetStep) {
      this.dataStore.onPutTargeting()
    }

    this.currentOrderStep = nextPage
  }

  @action public hideErrorPopup() {
    this.couldNotOrderProspect = false
  }

  @action public async onChangeTagFilter(value: string[]) {
    this.selectedTags = value
  }

  @computed get filteredAvailableImages() {
    let {
      configuration: { availableImages, allAvailableImages },
      campaignData: { images },
    } = this.dataStore.prospect

    if (!this.selectedTags?.length) return availableImages

    const activeTags = toJS(this.selectedTags)

    availableImages = allAvailableImages

    return (availableImages = availableImages
      .filter((media: any) => {
        if (!media.tags) return true

        return Object.keys(media.tags).some((locale) => {
          return media.tags[locale]?.some((mediaTag: string) => {
            return activeTags.some((s) => s.toLocaleLowerCase(locale) === mediaTag.toLocaleLowerCase(locale))
          })
        })
      })
      .filter((avImage) => !images.some((img) => img.url === avImage.url)))
  }

  /* FORM FUNCTIONS START */
  @action public onChangeObjectKey(
    objectName: string,
    key: string,
    value: string | number | boolean | Date | string[],
    nestedProperty?: string
  ): void {
    nestedProperty
      ? (this.dataStore.prospect[objectName][nestedProperty][key] = value)
      : (this.dataStore.prospect[objectName][key] = value)
  }

  @action public onChangeProspectKey(key: string, value: string | number | boolean | Date): void {
    this.dataStore.prospect[key] = value
  }

  @action public onChangeCustomReferences(key: string, value: string): void {
    this.dataStore.prospect.customReferences[key] = value
  }

  @action public onChangeRange(values: number[]) {
    this.dataStore.prospect.campaignData.targeting.audience.minAge = values[0]
    this.dataStore.prospect.campaignData.targeting.audience.maxAge = values[1]
  }

  @action public onChangeGender(genderKey: string, value: any) {
    this.dataStore.prospect.campaignData.targeting.audience.gender[genderKey] = value
  }

  @action public onStartAsap() {
    const prospectStart = this.dataStore.prospect.start
    this.dataStore.prospect.start = prospectStart >= new Date() ? addHoursClean(prospectStart, 1) : addHoursClean(new Date(), 1)
    this.dataStore.prospect.startAsap = !this.dataStore.prospect.startAsap
    this.onChangeProspectKey("start", addHoursClean(new Date(), 1))
  }

  @action public onAddEmailAddress(selectValue: [{ value: string }], collectionKey: "reportRecipients" | "campaignManagers") {
    if (selectValue.length) {
      let selection: string[] = []
      selectValue.forEach((element) => {
        selection.push(element.value)
      })
      this.dataStore.prospect[collectionKey] = selection
    } else {
      this.dataStore.prospect[collectionKey] = []
    }
  }

  @action public async onSelectAddress(address: string, id: string) {
    const geoPlaceResponse = await geocodeByPlaceId(id)
    await this.handleGeocode(geoPlaceResponse)
  }

  @action public async handleGeocode(results: any[]) {
    this.validatingLocations = true

    const geoLatLngResponse = await getLatLng(results[0])

    const locationObj = {
      data: results[0],
      name: results[0].formatted_address,
      type: results[0].types[0],
      radius: RADIUS_PER_GEOGRAPHIC_TYPES[results[0].types[0]],
      lat: Number(geoLatLngResponse.lat),
      lng: Number(geoLatLngResponse.lng),
    }
    this.dataStore.prospect.campaignData.targeting.locations = this.dataStore.prospect.campaignData.targeting.locations.filter(
      (locations) => {
        return !(locationObj.name.includes("Sweden") && locations.name === "Sweden")
      }
    )
    this.locationsValid = await validateLocationsOverlap([
      ...this.dataStore.prospect.campaignData.targeting.locations,
      locationObj,
    ])

    if (this.locationsValid) {
      this.address = ""
      this.dataStore.prospect.campaignData.targeting.locations.push(locationObj)
    }

    this.validatingLocations = false
  }

  @action public async updateGeoErrors(locations: ILocation[]) {
    this.locationsValid = await validateLocationsOverlap(locations)
  }

  @action public onChangeStringValue(key: string, value: string) {
    this[key] = value
  }

  @action public onChangeLocation(key: string, index: number, value: number | string | boolean) {
    this.dataStore.prospect.campaignData.targeting.locations[index][key] = value
  }

  @action public async onDeleteLocation(index: number) {
    this.validatingLocations = true
    this.dataStore.prospect.campaignData.targeting.locations.splice(index, 1)
    this.locationsValid = await validateLocationsOverlap(this.dataStore.prospect.campaignData.targeting.locations)
    this.validatingLocations = false
  }

  @action public async onDeleteZipCode(index: number) {
    this.dataStore.prospect.campaignData.targeting.zipCodes?.splice(index, 1)
  }

  @action public async onChangeImages(data: { images: any[]; availableImages: any[] }) {
    this.dataStore.prospect.campaignData.images = data.images
    this.dataStore.prospect.configuration.availableImages = data.availableImages
  }

  @action public onChangeImagesInput(index: number, key: string, value: string) {
    this.dataStore.prospect.campaignData.images[index][key] = value
  }

  @action public onChangeLandingPageSetting(value: LandingPageSetting) {
    this.dataStore.prospect.source.landingPageSetting = value
    this.validateLandingPages()
  }

  @action public validateLandingPages() {
    this.landingPagesValid =
      this.dataStore.prospect.source.landingPageSetting === LandingPageSetting.Unique
        ? this.dataStore.prospect.campaignData.images.every((x) => isValidHttpUrl(x.landingPage))
        : isValidHttpUrl(this.dataStore.prospect.campaignData.settings.landingPage)
  }
  /* FORM FUNCTIONS END */

  @computed get validateDailyBudget() {
    const { prospect } = this.dataStore
    return prospect
      ? validateDailyImpressions(
          calcDailyImpressions(
            prospect.start,
            prospect.end,
            prospect.startAsap,
            prospect.amount,
            prospect.configuration.currency.cpm
          )
        )
      : false
  }

  @computed get startEndToClose() {
    const { prospect } = this.dataStore
    return prospect
      ? differenceInHours(new Date(prospect.end), new Date(prospect.start)) < 48 ||
          differenceInHours(new Date(prospect.end), new Date()) < 48
      : false
  }

  @computed get hasDisplay() {
    return hasDisplayShare(this.dataStore.prospect.source.channelShares)
  }

  @computed get hasImages() {
    return this.hasDisplay
      ? this.dataStore.prospect.campaignData.images?.length >= this.dataStore.prospect.source.defaultMediaLibraryImages
      : !!this.dataStore.prospect.campaignData.images?.length
  }

  @computed get hasEmptyPhoneNumbers() {
    const { imagePhoneNumberOption, source } = this.dataStore.prospect
    if (imagePhoneNumberOption === ImageTextOption.Unique && source.phoneNumberSetting !== PhoneNumberSetting.Disabled)
      return this.dataStore.prospect.campaignData.images.some((image) => image.phoneNumber === "")

    return false
  }
}
