import * as ReactDOM from "react-dom/client"
import "utils/polyfills.ts"
import { Provider as MobxProvider } from "mobx-react"
import { isIE } from "react-device-detect"
import { RootStore } from "./mobxStores"
import App from "./App"
import { FaIcon } from "./components/common"
import NotSupportedView from "./components/wrappers/NotSupportedView"
import "./assets/icons"
import "normalize.css"
import { configure } from "mobx"
configure({ useProxies: "never", enforceActions: "never" })

ReactDOM.createRoot(document.getElementById("root")!).render(
  isIE ? (
    <NotSupportedView
      icon={<FaIcon icon="internet-explorer" prefix="fab" />}
      title="This webpage is not supported on Internet Explorer"
      subTitle="Please open the link in a modern browser like Chrome, Firefox or Edge"
    />
  ) : (
    <MobxProvider rootStore={new RootStore()}>
      <App />
    </MobxProvider>
  )
)
