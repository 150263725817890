import { Paper, CircularProgress } from "@mui/material"
import styled from "styled-components"
import Odometer from "./Odometer"
import { FaIcon } from "../common"
import { useState } from "react"
import { isMobile } from "react-device-detect"

const VisibilitySensor = require("react-visibility-sensor")

interface IStatisticsPaperProps {
  value: number | null
  text: string
  percentage?: boolean
  suffix?: string
  loading?: boolean
  className?: string
  color: string
  icon?: any //(props: icon) => JSX.Element;
  fontSize?: string
}

const StatisticsPaper = ({
  value,
  text,
  percentage,
  suffix,
  loading,
  color,
  className,
  icon,
  fontSize,
}: IStatisticsPaperProps) => {
  const [visible, setVisible] = useState<boolean>()

  function changeVisibility(isVisible: boolean) {
    if (isMobile) {
      setVisible(isVisible)
    } else {
      setVisible(true)
    }
  }

  return (
    <CustomPaper className={className}>
      <VisibilitySensor onChange={changeVisibility}>
        <StatsWrapper>
          {!loading && icon && (
            <IconWrapper>
              <FaIcon icon={icon} fontSize={fontSize} />
            </IconWrapper>
          )}
          <PaperTitle color={color}>
            {loading ? <CircularProgress thickness={3} size={40} /> : <Odometer value={visible ? value : 0} />}
            {!loading && percentage && <Percentage>%</Percentage>}
            {!loading && suffix && <Suffix>{suffix}</Suffix>}
          </PaperTitle>
          <CustomText>{text}</CustomText>
        </StatsWrapper>
      </VisibilitySensor>
    </CustomPaper>
  )
}
export default StatisticsPaper

const CustomPaper = styled(Paper)`
  text-align: center;
  padding: 2em 1.5em;
  position: relative;
  height: 150px;
  overflow: hidden;
  margin-bottom: 1em;

  @media only screen and (min-width: 768px) {
    :not(:last-child) {
      margin-right: 0.4em;
    }

    width: 30%;
    display: inline-block;
  }
`
const StatsWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  width: 90%;
`
const IconWrapper = styled.div`
  font-size: 3em;
  color: hsla(210, 17%, 69%, 1);
  @media (max-width: 700px) {
    top: 30%;
    font-size: 3rem;
  }
`

const PaperTitle = styled.div`
  font-size: 35px;
  color: ${(p) => p.color};
  font-weight: bold;
`

const Percentage = styled.span`
  font-size: 32px;
`

const Suffix = styled.span`
  font-size: 28px;
  color: hsla(210, 17%, 69%, 1);
`

const CustomText = styled.p`
  font-size: 15px;
  font-weight: lighter;
`
