import jwtDecode, { JwtPayload } from "jwt-decode"
import { dateInPast } from "./dateHelpers"

export const refreshTokenExpiresIn = 60
const refreshTokenName = "token_refresh"
const tokenName = "token"

export const validateToken = (token: string | null): boolean => {
  const tokenQueryParsed = "null"
  if (token && token !== tokenQueryParsed) {
    const expiresAt = jwtDecode<JwtPayload>(token).exp
    return !expiresAt || dateInPast(+expiresAt * 1000) ? false : true
  }
  return false
}

export const destroyToken = () => {
  localStorage.removeItem(tokenName)
  localStorage.removeItem(refreshTokenName)
}

export const setTokenAndRefreshToken = (token: string, refreshToken: string) => {
  localStorage.setItem(tokenName, token)
  localStorage.setItem(refreshTokenName, refreshToken)
}

export const getCurrentTokens = (): { token: string | null; refreshToken: string | null } => ({
  token: localStorage.getItem(tokenName),
  refreshToken: localStorage.getItem(refreshTokenName),
})
