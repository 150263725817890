import en from "assets/translations/en"
import { IntlProvider } from "react-intl"
import { Locales, validateLocaleFallbackEnglish } from "utils/locale"
import { createContext, FC, Dispatch, SetStateAction, useState, useCallback, useMemo } from "react"

interface IIntlProviderValue {
  locale: string
  messages: { [key: string]: string }
  switchLocale: Dispatch<SetStateAction<string>>
}

const initialContext: IIntlProviderValue = {
  locale: "en",
  messages: en,
  switchLocale: (): void => {
    throw new Error("setContext function must be overridden")
  },
}

const LocaleContext = createContext<IIntlProviderValue>(initialContext)

const LocaleContextProvider: FC = ({ children }) => {
  const validatedLocale = validateLocaleFallbackEnglish(window.tenant?.language as Locales)
  const [locale, setLocale] = useState(validatedLocale.locale)
  const [messages, setMessages] = useState(validatedLocale.messages)

  const switchLocale = useCallback(
    (locale: Locales) => {
      const validatedLocale = validateLocaleFallbackEnglish(locale)
      setLocale(validatedLocale.locale)
      setMessages(validatedLocale.messages)
    },
    [setLocale, setMessages]
  )

  const value = useMemo(() => ({ locale, messages, switchLocale }), [locale, messages, switchLocale])
  return (
    <LocaleContext.Provider value={value}>
      <IntlProvider messages={messages} locale={locale}>
        {children}
      </IntlProvider>
    </LocaleContext.Provider>
  )
}
export { LocaleContextProvider, LocaleContext }
