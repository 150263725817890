export enum Channels {
  Facebook = "facebook",
  Bidtheatre = "bidtheatre",
}

export const prepareFacebookName = (name: string) =>
  !/(^http)/.test(name) && /^(facebook)|(www)/.test(name) ? "https://" + name : name

const getSetTemplateHash = () => {
  let templateHash = sessionStorage.getItem("templateHash")
  if (!templateHash) {
    templateHash = new Date().getTime().toString()
    sessionStorage.setItem("templateHash", templateHash)
  }
  return templateHash
}

export const handleAdItemUrl = (url: string, templateId?: string, dataSourceId?: string, itemId?: string, channel?: Channels) => {
  let templateHash = getSetTemplateHash()

  if (!url) return url
  url = !url.startsWith("http") ? `${window.tenant.cdn}${url}` : url
  const isClientOrAsset = url.indexOf("/client/") !== -1 || url.indexOf("/assets/") !== -1
  if (isClientOrAsset && templateId && dataSourceId && itemId) {
    url = `${url}/${templateId}/${channel || Channels.Facebook}/${dataSourceId}/${itemId}?${templateHash}`
  } else {
    url =
      templateId && url.startsWith(`${window.tenant.cdn}`)
        ? `${url}/${templateId}/${channel || Channels.Facebook}?${templateHash}`
        : url
  }
  return url
}

export const generateComposedImageUrl = (
  channel: "facebook" | "bidtheatre",
  templateId: string,
  dataSourceId: string,
  itemId: string
) => {
  let templateHash = getSetTemplateHash()
  return `${window.tenant.cdn}/render/${templateId}/${channel}/${dataSourceId}/${itemId}?${templateHash}`
}

export const handleImageError = (e: any) => {
  const img = e.target as HTMLImageElement
  let attempts = (e.target.attempts as number) || 1
  e.target.attempts = attempts + 1
  const original = img.src.replace(/([^_]*).*$/, "$1")

  if (attempts > 15) {
    console.warn("Kunde inte ladda " + original + " efter 15 försök")
  } else {
    img.src = `${original}_${attempts}`
  }
}
