import { TemplateChannel } from "entities/enums"

export const hasDisplayShare = (channelShares: Record<TemplateChannel, number> | null): boolean => {
  if (!channelShares) return false

  // Check if BidTheatre key is present
  if (!channelShares.hasOwnProperty(TemplateChannel.BidTheatre)) {
    return false
  }

  // Check if BidTheatre key has a value greater than 0
  if (channelShares[TemplateChannel.BidTheatre] > 0) {
    return true
  }

  return false
}
