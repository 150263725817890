import styled from "styled-components"

const CheckmarkCircle = () => (
  <CheckmarkContainer>
    <CheckmarkWrapper>
      <div className="checkmarkContainer">
        <div className="checkmark" />
      </div>
    </CheckmarkWrapper>
  </CheckmarkContainer>
)

export default CheckmarkCircle

const CheckmarkContainer = styled.div`
  display: flex;
  justify-content: center;
`
const CheckmarkWrapper = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
  width: 200px;
  height: 200px;

  color: ${(p) => p.theme.white};
  background-color: #85bb65;
  box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease 0s;
  border-radius: 50%;

  .checkmarkContainer {
    padding: 40px 55px 5px 0px;
    .checkmark {
      background: white;
      height: 20px;
      position: relative;
      transform: rotate(45deg);
      animation: checkmark-width 0.15s linear;
      animation-fill-mode: forwards;
      &::after {
        background: white;
        content: "";
        position: absolute;
        right: 0;
        bottom: 0;
        width: 20px;
        height: 0;
        animation: checkmark-height 0.15s linear 0.15s;
        animation-fill-mode: forwards;
      }
    }
    @keyframes checkmark-width {
      from {
        width: 0;
      }
      to {
        width: 60px;
      }
    }

    @keyframes checkmark-height {
      from {
        height: 0;
      }
      to {
        height: 120px;
      }
    }
  }
`
