import axios from "axios"
import { IDataSourceData } from "entities/interfaces"

interface IFacebookResponse {
  pageName: string
  pageThumbnailUrl: string
}

export interface IPreviewResponse {
  facebook: IFacebookResponse
  dataSourceId: string
  templateId: string
  data: IDataSourceData
}

export const getPreviewData = (previewId: string) => axios.get<IPreviewResponse>(`${window.tenant.api}/Preview/${previewId}`)
