export default {
  "public.targetGroup": "Målretning",
  "public.adCorrection": "Proof",
  "public.adBudget": "Køb",
  "public.sponsored": "Sponsoreret",
  "public.swipe": "Stryg",
  "public.uniquePerImage": "Hvis Unikt pr. objekt vælges, skal du rulle ned for at ændre teksten",
  "public.gender&age": "Køn og alder",
  "public.emailExample": "eksempel@e-mail.dk",
  "public.startDate": "Startdato",
  "public.endDate": "Slutdato",
  "public.topMedia": "Populære medier",
  "public.social": "Sociale medier",
  "public.display": "Skærm",
  "public.webTv": "Web-tv",
  "public.video": "Video",
  "public.digitalScreen": "Digital skærm (DOOH)",
  "public.devices": "Enheder",
  "public.media": "Medier",
  "public.url": "Webadresse",
  "public.clicks": "klik",
  "public.createLink": "Opret link",
  "public.generateReport": "Generer rapport",
  "public.showFullReport": "Vis fuld rapport",
  "public.impressions": "Eksponeringer",
  "public.reach": "Rækkevidde",
  "public.reachPerPerson": " gange/person",
  "public.ctr": "CTR",
  "public.ctrReach": "CTR-rækkevidde",
  "public.ctrImpressions": "CTR-eksponeringer",
  "public.channels": "Kanaler",
  "public.mobile": "Mobil",
  "public.desktop": "Computer",
  "public.fbMobile": "Facebook på mobil",
  "public.fbDesktop": "Facebook på computer",
  "public.mediaBudget": "Mediebudget",
  "public.geoPlaces": "Placeringer for geografisk målretning",
  "public.reportRecipient": "Rapportmodtagere",
  "public.campaignManagers": "Kampagneadministratorer",
  "public.newAdImages": "Upload materiale til din annonce",
  "public.currentAdImages": "Aktuelt materiale til din annonce",
  "public.adLandingPage": "Landingsside",
  "public.report": "Rapport",
  "public.orderProofStep": "Vælg tekst",
  "public.orderTargetingStep": "Vælg placeringer for geografisk målretning og målgruppe",
  "public.orderLocationStep": "Vælg placeringer for geografiske målretning",
  "public.orderBudgetStep": "Vælg budget, tidsplan og kontrolpanelsmodtagere",
  "public.adTextAboveImage": "Annoncebeskrivelse over billede",
  "public.adTitleBelowImage": "Titel under annonce",
  "public.descBelowImage": "Beskrivelse under annonce",
  "public.otherImages": "Andet materiale, der skal bruges i din annonce",
  "public.costCenter": "Omkostningssted",
  "public.chooseMediaBudget": "Vælg mediebudget",
  "public.addLinkedInJobPost": "Vælg mediebudget for jobopslag på LinkedIn",
  "public.linkedInAmount": "LinkedIn-beløb",
  "public.noLinkedJobPost": "Intet LinkedIn-jobopslag",
  "public.purchase": "Køb",
  "public.purchaser": "Køber",
  "public.purchased": "Købt",
  "public.perDayCost": "{value} DKK pr. dag",
  "public.sendNewLogin": "Send nyt login",
  "public.extendAd": "Forlæng annonce",
  "public.adTotal": "Total:",
  "public.targetGroupControlledAds": " Målrettede eksponeringer",
  "public.age": "Alder",
  "public.gender": "Køn",
  "public.man": "Mand",
  "public.men": "Mænd",
  "public.woman": "Kvinde",
  "public.women": "Kvinder",
  "public.reference": "Reference",
  "public.like": "Synes godt om",
  "public.comment": "Kommentar",
  "public.share": "Deling",
  "public.comments": "{value} kommentarer",
  "public.shares": "{value} delinger",
  "public.minMaxAge": "Min. {min}. Maks. {max}",
  "public.openNewWindow": "Åbn i nyt vindue",
  "public.order": "Afgiv bestilling",
  "public.next": "Næste",
  "public.previous": "Forrige",
  "public.adPeriod": "Periode",
  "public.companyName": "Virksomhedsnavn",
  "public.uploadImage": "Upload billede",
  "public.asap": "Start så hurtigt som muligt",
  "public.totalValue": "Total {value}",
  "public.lastShowDate": "Registreret slutdato som standard",
  "public.createNewAdMultiEmail":
    "Angiv eventuelle yderligere e-mailadresser, du vil modtage kampagnekontrolpanelet på. Du kan tilføje flere e-mailadresser.",
  "public.hintCampaignManagers":
    "Angiv eventuelle yderligere e-mailadresser, du vil autorisere til at forlænge kampagnen. Du kan tilføje flere e-mailadresser.",
  "public.addNewEmail": "Tilføj e-mailadresse – flere e-mailadresser kan angives",
  "public.shortTextRecomended":
    "Gør det kort – vi anbefaler {recomendedLength} tegn eller derunder. Du har i øjeblikket brugt {value} tegn.",
  "public.linkedInReporting": "Vi rapporterer ikke automatiske målretningsannoncer fra LinkedIn",
  "public.vatPrices": "Priser {vatType} {vatSuffix}",
  "public.vatPricesFull": "Priser {vatType} {vatRate} % {vatSuffix}",
  "public.validatingLocations": "Validering af geoplaceringer",
  "public.tags": "Tilføj flere tags, skriv tekst, og tryk på Enter.",
  "public.tenantMediaLocale": "Tilføj lokation ved at skrive tekst, og tryk derefter på Enter, eller vælg mellem mulighederne.",
  "public.tenantMediaTags": "Tilføj flere tags, skriv tekst, og tryk derefter på Enter, eller vælg mellem mulighederne.",
  "public.tenantMediaCategories": "Tilføj tekst, og tryk derefter på Enter, eller vælg mellem mulighederne.",
  "public.newAdConfirmation": "Du vil modtage en bekræftelse på din bestilling på den angivne e-mailadresse.",
  "public.adCreated": "Annoncen er blevet oprettet, og du kan nu lukke dette vindue.",
  "public.minBudget": " Der skal være mindst {value} eksponeringer om dagen for at afgive bestillingen.",
  "public.dragAndDropImages": 'Bestem rækkefølgen, og vælg hvilke billeder der skal vises ved brug af "Træk og slip"',
  "public.addTargetGroupLocation": "Tilføj målrettede geografiske placeringer ved at søge efter steder eller adresser.",
  "public.more": "...Mere",
  "public.readMore": "...Læs mere",
  "public.fileDrop": "Klik for at vælge filer, eller træk dem hertil for at uploade",
  "public.orders": "Bestillinger",
  "public.exclude": "F.eks.",
  "public.include": "Inkl.",
  "public.vatSuffix": "Moms",
  "public.vat": "{vatType} {vatRate} % {vatSuffix}",
  "public.vatNoRate": "{vatType} {vatSuffix}",
  "public.loading": "Indlæser...",
  "public.newLoginSent": "Nyt login sendt",
  "public.errorLogin": "Fejl, kontakt",
  "public.requestNewLogin": "Anmod om et nyt login via e-mail",
  "public.noImageSelected": "Intet billede valgt",
  "public.multiImageCategories": "Vælg en eller flere billedkategorier",
  "public.infoSpecialAdCategory":
    "Demografiske indstillinger låst i overensstemmelse med Metas (Facebook og Instagram) særlige annoncepolitik",
  "public.edit": "Rediger",
  "public.success": "Gennemført!",
  "public.orderPlaced": "Tak for din bestilling!",
  "public.hint.image": "Accepterede filtyper: JPG/PNG/BMP",
  "public.hint.font": "Accepterede filtyper: .ttf/.woff",
  "public.youTube": "YouTube",
  "public.date": "Dato",
  "public.unauthorized": "Uautoriseret: Adgang afvist.",
  "tooltip.prefilledField":
    "Hvis du ønsker at tilsidesætte det forudfyldte standardfelt: Reference (referencenummer, omkostningssted eller person)",
  "title.prefilledField": "Forududfyldt omkostningssted",
  "imageTextOption.same": "Samme for alle",
  "imageTextOption.unique": "Unikt pr. objekt",
  "landingPageSetting.uneditable": "Ikke redigerbar",
  "landingPageSetting.same": "Samme for alle",
  "landingPageSetting.unique": "Unik",
  "common.noCategory": "Ingen kategori",
  "common.all": "Alle",
  "common.inactive": "Inaktiv",
  "common.addValue": "Tilføj {value}",
  "common.timeInterval": "Brug brugerdefineret interval",
  "common.showDayAndTime": "Dag og tidsinterval",
  "common.customDateAndTime": "Dato og tidspunkt",
  "common.crawlingSpindleSpeed": "Spindelhastighed",
  "common.customDaySpeed": "Ugedage",
  "common.customHourSpeed": "Timer",
  "common.customMinuteSpeed": "Minutter",
  "common.cronExpression": "Brug CRON-udtryk",
  "common.cronStatement": "ALTERNATIVT kan du bruge CRON-udtryk nedenfor",
  "common.valid": "Gyldig",
  "common.invalid": "Ugyldig",
  "confirm.useInactiveDataSourceOnCampaign":
    "Er du sikker på, at du ønsker at indstille den inaktive datakilde ({dataSource}) for {campaign}?",
  "title.selectedDay": "Valgt dag",
  "title.metaMedia": "Meta-medie (Facebook og Instagram)",
  "documentTitle.resultReport": "Præstationsrapport for {campaignName}",
  "calltoaction.NO_BUTTON": "Ingen knap",
  "calltoaction.BUY_NOW": "Køb nu",
  "calltoaction.APPLY_NOW": "Ansøg nu",
  "calltoaction.BOOK_TRAVEL": "Reservér nu",
  "calltoaction.WATCH_MORE": "Se mere",
  "calltoaction.CONTACT_US": "Kontakt os",
  "calltoaction.DOWNLOAD": "Download",
  "calltoaction.GET_OFFER": "Få tilbud",
  "calltoaction.GET_QUOTE": "Få overslag",
  "calltoaction.SIGN_UP": "Tilmeld dig",
  "calltoaction.SUBSCRIBE": "Abonnér",
  "calltoaction.INTERESTED": "Interesseret",
  "calltoaction.GET_SHOWTIMES": "Hent visningstider",
  "calltoaction.REQUEST_TIME": "Anmodningstidspunkt",
  "calltoaction.LEARN_MORE": "Få mere at vide",
  "calltoaction.CALL_NOW": "Ring nu",
  "error.required": "Dette felt er påkrævet",
  "error.cpm": "CPM skal være et tal i intervallet 65 til 900",
  "error.number": "Dette felt skal være et tal",
  "error.chooseOneGender": "* Mindst et køn",
  "error.dailyBudgetLow": "Mediebudgettet er for lavt. Øg mediebudgettet over det nuværende, eller forkort kampagnetidsplanen.",
  "error.email": "Angiv en gyldig e-mail",
  "error.generic": "Noget gik galt, prøv igen",
  "error.imageUploadError": "Kunne ikke gemme billeder",
  "error.oneTargetGroupArea": "Mindst én geografisk placering",
  "error.locationOverlap": "Geografiske placeringer må ikke overlappe hinanden",
  "error.noFacebookPage": "Angiv webadressen for din Facebook-side for at fortsætte",
  "error.invalidFacebookPage": "Facebook-siden er ikke korrekt, tjek din stavning",
  "error.badLink": "Landingssiden er ikke korrekt, tjek din stavning",
  "error.noLandingPage": "Du skal angive din landingsside",
  "error.noGenderChoosen": "Intet køn valgt",
  "error.noTargetGroupArea": "Mindst én geografisk placering skal angives",
  "error.locationNoLocationOverlap": "Mindst én geografisk placering skal angives, og placeringerne må ikke overlappe",
  "error.closeEndDate": "Slutdatoen er kun 48 timer væk fra startdatoen – slutdatoen skal være 48 timer ud i fremtiden",
  "error.orderCouldNotComplete": "Bestillingen kunne ikke behandles",
  "error.fileTobig": "Filen er for stor og må maks. være 50 MB – vælg en anden fil",
  "error.notImageFile": "Filen skal være et billede – vælg en anden fil",
  "error.couldNotExtendProspect": "Kunne ikke forlænge annoncen, prøv igen senere.",
  "error.couldNotOrderAd": "Kunne ikke bestille annoncen",
  "error.errorMessageMoreMinute": "Minutter skal være større end 0",
  "error.errorMessageLessMinute": "Minutter må højst være 59",
  "error.errorMessageMoreHours": "Timer skal være større end 0",
  "error.errorMessageLessHours": "Timer må højst være 23",
  "public.error.proofRequired": "Mindst ét billede er påkrævet",
  "public.error.proofDisplayRequired": "Der kræves mindst {value} billeder",
  "public.error.hasEmptyPhoneNumbers": "Telefonnummerfeltet må ikke være tomt",
  "public.error.file-invalid-type": "Ugyldig filtype.",
  "public.error.file-too-large": "Filen er for stor og må maks. være 50 MB – vælg en anden fil",
  "public.error.too-many-files": "For mange filer – vælg én",
  "public.error.pageNotFound": "Siden blev ikke fundet!",
  "public.error.pageNotFoundDescription": "Angiv en gyldig webadresse, eller kontakt support.",
  "public.error.pageNetworkError": "Intern serverfejl",
  "public.error.pageNetworkErrorDescription": "Prøv igen senere, eller kontakt support.",
  "public.error.landingPages": "Alle landingssider skal være en gyldig webadresse.",
  "billingType.unspecified": "Uspecificeret",
  "billingType.monthly": "Månedligt på forhånd",
  "billingType.monthlyArrears": "Månedligt bagud",
  "billingType.quarterly": "Kvartalsvis på forhånd",
  "billingType.semiYearly": "Halvårligt på forhånd",
  "billingType.yearly": "Årligt på forhånd",
  "billingType.onDemand": "Ved bestilling i webshoppen",
  "billingType.external": "Ekstern",
  "title.apiChannels": "Tilladte API-kanaler",
  "title.useCustomStaticCreatives": "Brug brugerdefinerede statiske annoncer",
  "title.linkedInPageId": "LinkedIn reference-id",
  "title.linkedInAdAccountId": "LinkedIn-annoncekonto-id ",
  "title.enablePinMeTo": "Aktiver PinMeTo",
  "title.pinMeToCampaign": "Indstil som PinMeTo-kampagne",
  "title.integratePinMeToCampaign":
    "Kampagnen {integratedCampaign} er integreret. Bekræft for at tilsidesætte med kampagne {campaignName}",
}
