import React from "react"
import styled from "styled-components"
import { FaIcon } from "../../common"

interface INotSupportedView {
  title: string
  subTitle: string
  icon: JSX.Element
}

const NotSupportedView = ({ title, subTitle, icon }: INotSupportedView) => (
  <Container>
    <Content>
      <IconWrapper>
        {icon}

        <SlashIcon>
          <FaIcon icon="slash" />
        </SlashIcon>
      </IconWrapper>
      <h2>{title}</h2>
      <h4>{subTitle}</h4>
    </Content>
  </Container>
)
export default NotSupportedView

const Container = styled.div`
  width: 100%;
  height: 100vh;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);
  text-align: center;
  background: #ffffff;
  display: flex;
  justify-content: center;

  h2,
  h3 {
    color: #191c1f;
    max-width: 290px;
  }

  h3 {
    margin: 0;
    font-weight: initial;
  }
`

const Content = styled.div`
  width: 100%;
  border-top: 6px solid var(--brand-innovation);
  padding: 0 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const IconWrapper = styled.div`
  font-size: 110px;
  color: #f1f2f6;
`

const SlashIcon = styled.span`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
`
