/* eslint-disable @typescript-eslint/no-unused-vars */

import { action, observable, computed, toJS, makeObservable } from "mobx"
import { IClient, IFacebookData } from "entities/interfaces"
import * as API from "api"
import { ProspectCampaignRequest } from "entities/models/Prospect"
import { calculateImpressionsCpm } from "utils/calculations"
import { CallToActionType } from "entities/enums"
import { validateLocationsOverlap } from "utils/locationHelpers"
import { AdProspectType, IOrderDataStore, OrderStore } from "./OrderStore"
import axios from "axios"
import { handleDemoOrderIds, mockedFacebookData } from "utils/MockedData/facebookData"
import toast from "react-hot-toast"
/* eslint-enable @typescript-eslint/no-unused-vars */

export class OrderDemoStore implements IOrderDataStore {
  @observable public client: IClient
  @observable public prospect: ProspectCampaignRequest
  @observable public facebookData: IFacebookData

  @observable public orderStore: OrderStore
  @observable public validatingLocations: boolean

  constructor(orderStore: OrderStore) {
    makeObservable(this)
    this.orderStore = orderStore
  }

  @action public async init(
    slug: string,
    draftId: string,
    search: string,
    adProspectType: AdProspectType,
    startOnLastStep: boolean
  ) {
    try {
      const demoCompanyId = "deee869b-4134-4546-9c96-dd06dac3acee"
      let clientResponse = await axios.get(`${window.tenant.api}/client/${demoCompanyId}/public`)

      if (adProspectType === AdProspectType.dutchDemo)
        clientResponse.data = { ...clientResponse.data, languageCode: "nl", currencyCode: "EUR" }
      if (adProspectType === AdProspectType.finnishDemo)
        clientResponse.data = { ...clientResponse.data, languageCode: "fi", currencyCode: "EUR" }

      this.client = clientResponse.data

      const prospectDemoId = handleDemoOrderIds(adProspectType)

      const prospectResponse = await API.Prospect.getById(prospectDemoId)

      this.prospect = new ProspectCampaignRequest(prospectResponse.data)

      this.facebookData = mockedFacebookData

      const templateResponse = await API.Template.getById(this.prospect.source.templateId)
      this.facebookData.callToAction = templateResponse.data.channels.facebook?.callToAction || CallToActionType.NoButton
      this.prospect.channels = templateResponse.data.channels
    } catch (error: unknown) {
      if (axios.isAxiosError(error)) {
        if (!error.response || error.response?.status >= 500) throw new Error("Network Error")
      }
      const errorMessage = `${JSON.stringify(error, Object.getOwnPropertyNames(error))}\r\n${error}`
      toast.error(`${errorMessage}`, { duration: Infinity })
    }
  }

  @action public async onPutProof() {
    const settingsRequest = API.Prospect.mapCampaignDataSettingsToProspectSettingsRequest(
      this.prospect.campaignData,
      this.prospect.imageTitleOption,
      this.prospect.imageDescriptionOption,
      this.prospect.imagePhoneNumberOption,
      this.prospect.campaignData.settings.overrideImageTitles,
      this.prospect.campaignData.settings.overrideImageDescriptions,
      this.prospect.campaignData.settings.overrideImagePhoneNumbers
    )

    console.log("Images would be commited with:", toJS(this.prospect.campaignData.images))
    console.log("Settings would be commited with:", settingsRequest)
  }

  @action public async onPutTargeting() {
    console.log("Targeting would be commited with:", toJS(this.prospect.campaignData.targeting))
  }

  @action public async onBuyOrder() {
    this.orderStore.loading = true
    console.log("Would be commited with:", this.prospect)
    this.orderStore.prospectOrderDone = true
    this.orderStore.loading = false
  }

  @action public async validateLocationsInitial() {
    const locations = this.prospect.campaignData.targeting.locations
    if (locations.length) {
      this.validatingLocations = true
      this.orderStore.locationsValid = await validateLocationsOverlap(locations)
      this.validatingLocations = false
    }
  }

  /* VALIDATION FUNCTIONS END */

  @computed get targetedImpressions() {
    return this.prospect ? calculateImpressionsCpm(this.prospect.amount, this.prospect.configuration.currency.cpm) : 0
  }

  @computed get totalBudget() {
    return this.prospect ? Number(this.prospect.amount) + Number(this.prospect.linkedInAmount) : 0
  }
}
