import { useIntl } from "react-intl"
import styled from "styled-components"

interface IProspectSuccessProps {
  header: string
  description: string
}

const ProspectSuccess = ({ header, description }: IProspectSuccessProps) => {
  const { formatMessage } = useIntl()
  return (
    <CustomSuccess>
      <CustomParagraph>{description}</CustomParagraph>
      <CustomParagraph>{formatMessage({ id: "public.newAdConfirmation" })}</CustomParagraph>
    </CustomSuccess>
  )
}
export default ProspectSuccess

const CustomSuccess = styled.div`
  display: flex;
  align-items: left;
  flex-direction: column;
`

const CustomParagraph = styled.p`
  opacity: 0.7;
  margin: 5px 0;
`
