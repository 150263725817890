import { AdChannelType, CampaignStateType } from "entities/enums"

export const handleChannelIcon = (channelType: AdChannelType) => {
  switch (channelType) {
    case AdChannelType.BidTheatre:
      return { prefix: "fas", name: "newspaper" }
    case AdChannelType.Facebook:
      return { prefix: "fab", name: "facebook-f" }
    case AdChannelType.LinkedIn:
      return { prefix: "fab", name: "linkedin-in" }
    case AdChannelType.YouTube:
      return { prefix: "fab", name: "youtube" }
    case AdChannelType.YouTubeGoogleAds:
      return { prefix: "fab", name: "youtube" }
    default:
      return { prefix: "fas", name: "question" }
  }
}

export const getCampaignStateIcons = (state: CampaignStateType) => {
  switch (state) {
    case CampaignStateType.Archived:
      return "archive"
    case CampaignStateType.Paused:
      return "pause"
    case CampaignStateType.Ended:
      return "check"
    case CampaignStateType.Scheduled:
      return "clock"
    case CampaignStateType.Live:
      return "rocket"
    default:
      return "exclamation-triangle"
  }
}
