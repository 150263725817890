/* eslint-disable @typescript-eslint/no-unused-vars */
import { action, observable, makeObservable } from "mobx"
import { ErrorModel } from "./ErrorModel"
import { IDataSourceProviderEdit } from "entities/interfaces"
/* eslint-enable @typescript-eslint/no-unused-vars */
export const dataSourceProvidersValidators = {
  name: ["empty"],
  providerCode: ["empty", "isValidJson"],
}

export interface IDataSourceProvidersErrorState {
  name: boolean
  providerCode: boolean
}

export interface IDataSourceProvidersRequest {
  id: string
  name: string
  providers: IDataSourceProviderEdit[]
  providerCode: string
}

const DefaultDataSourceProvidersRequest: IDataSourceProvidersRequest = {
  id: "",
  name: "",
  providers: [
    {
      url: "",
      container: "",
      containerTransformScript: "",
      userAgent: "",
      headers: {},
      formData: {},
      exclusions: [],
      filters: [],
      fields: { root: [], items: [] },
      requestVerificationToken: undefined,
      requestCredentialsToken: undefined,
      resolveCanonical: false,
    },
  ],
  providerCode: "",
}

export class DataSourceProvidersRequest implements IDataSourceProvidersRequest {
  @observable public id: string
  @observable public name: string
  @observable public providerCode: string
  @observable public providers: IDataSourceProviderEdit[]
  @observable public errorModel: ErrorModel<IDataSourceProvidersErrorState>
  @observable public dataSourceProvidersValidators: Record<string, string[]>

  constructor(dataSource: IDataSourceProvidersRequest = DefaultDataSourceProvidersRequest) {
    makeObservable(this)
    this.fromJson(dataSource)
    this.dataSourceProvidersValidators = dataSourceProvidersValidators
    this.errorModel = new ErrorModel({ name: false, providerCode: false })
  }

  @action public fromJson(dataSource: IDataSourceProvidersRequest): void {
    this.id = dataSource.id
    this.name = dataSource.name
    this.providers = []
    dataSource.providers.forEach((provider: IDataSourceProviderEdit) => {
      this.providers.push({
        template: provider.template,
        url: provider.url,
        container: provider.container,
        containerTransformScript: provider.containerTransformScript,
        userAgent: provider.userAgent,
        takeTop: provider.takeTop,
        sorting: provider.sorting,
        headers: provider.headers,
        formData: provider.formData,
        exclusions: provider.exclusions,
        filters: provider.filters,
        fields: provider.fields,
        requestVerificationToken: provider.requestVerificationToken,
        requestCredentialsToken: provider.requestCredentialsToken,
        resolveCanonical: provider.resolveCanonical,
        follow: provider.follow,
        restrictNumberOfResults: provider.takeTop ? true : false,
        inheritSettings: provider.template ? true : false,
        collapsed: true,
        verifyRequest: provider.requestVerificationToken ? true : false,
        credentialsRequest: provider.requestCredentialsToken ? true : false,
        sortResult: provider.sorting ? true : false,
      })
    })
    this.providerCode = dataSource.providers.length > 0 ? JSON.stringify(dataSource.providers, null, 2) : ""
  }
}
