import React from "react"
import styled from "styled-components"
import { useSpring, animated } from "react-spring"
import { useIntl } from "react-intl"

const NoFacebookAdPage = () => {
  const { formatMessage } = useIntl()
  const animationProps = useSpring({
    opacity: 1,
    transform: "translateY(0)",
    from: { opacity: 0, transform: "translateY(-50px)" },
  })

  return (
    <Wrapper>
      <Card style={animationProps}>
        <Logo src={`${window.tenant.logoUri}/logo-inverted.png`} />
        <Title>{formatMessage({id: "public.noFacebookAd"})}</Title>
        <Message>{formatMessage({ id: "public.later" })}</Message>
      </Card>
    </Wrapper>
  )
}

export default NoFacebookAdPage

const Wrapper = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f4f4f8;
`

const Card = styled(animated.div)`
  padding: 40px 60px;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  text-align: center;
  max-width: 500px;
  width: 100%;

  @media (max-width: 600px) {
    padding: 20px 30px;
  }
`

const Logo = styled.img`
  max-width: 100px;
  display: block;
  margin: 0 auto 20px;
  padding: 10px;
  background-color: black;

  @media (max-width: 600px) {
    max-width: 70px;
    padding: 7px;
  }
`

const Title = styled.h1`
  font-size: 3rem;
  margin-bottom: 24px;

  @media (max-width: 600px) {
    font-size: 2rem;
  }
`

const Message = styled.p`
  font-size: 1.25rem;
  color: #666;

  @media (max-width: 600px) {
    font-size: 1rem;
  }
`

