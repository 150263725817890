import { defaultTheme } from "../assets/theme"
import { createContext, FC, Dispatch, SetStateAction, useState, useCallback, useMemo } from "react"
import { DefaultTheme } from "styled-components"
import { ThemeProvider } from "styled-components"

interface ProviderValue {
  switchClientTheme: Dispatch<SetStateAction<string>>
  theme: DefaultTheme
}

const initialContext: ProviderValue = {
  theme: defaultTheme,
  switchClientTheme: (): void => {
    throw new Error("setContext function must be overridden")
  },
}
const ThemeContext = createContext<ProviderValue>(initialContext)

const StyledThemeContextProvider: FC = ({ children }) => {
  const [theme, setTheme] = useState(defaultTheme)
  const switchClientTheme = useCallback(
    (clientColor: string) => setTheme({ ...defaultTheme, primaryColor: clientColor }),
    [setTheme]
  )

  const value = useMemo(() => ({ switchClientTheme, theme }), [switchClientTheme, theme])

  return (
    <ThemeContext.Provider value={value}>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </ThemeContext.Provider>
  )
}
export { StyledThemeContextProvider, ThemeContext }
