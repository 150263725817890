import { ImageTextOption, PhoneNumberSetting } from "entities/enums"
import { ICampaignModel, IProspectCampaignRequest, IProspectViewModel, ITargeting } from "entities/interfaces"
import { get, post, put } from "api/restClient"

export interface IProspectSettingsRequest {
  facebookPage: string
  landingPage: string
  header?: string
  displayHeader?: string
  overrideImageTitles?: string
  overrideImageDescriptions?: string
  overrideImagePhoneNumbers?: string
  phoneNumberSetting: PhoneNumberSetting
}

export interface IProspectDataImage {
  url: string
  title?: string
  displayTitle?: string
  description?: string
  phoneNumber?: string | null
  displayDescription?: string
  landingPage?: string
  templateData?: Record<string, any>
}

export interface IProspectClientAssetResponse {
  id: string
  clientId: string
  name: string
  createdAt: Date
  contentType: string
  category: string
  path: string
  templateData?: Record<string, any>
  videoUrl?: string
  hasVideoUrl?: boolean
}

export interface IExtendBudgetRequest {
  amount: number
  linkedInAmount: number
  start: Date | null
  end: Date
  reference: string
  customReferences: Record<string, string>
}

export interface ISetBudgetRequest {
  amount: number
  linkedInAmount: number
  start: Date | null
  end: Date
}

export interface ICommitDraftBudgetResponse {
  campaignId: string
  orderId: string
  draftId: string
  amount: number
}

export interface ICommitBudgetRequest {
  reference: string
  customReferences: Record<string, string>
}

export interface IProspectAssetResponse {
  clientId: string
  id: string
  path: string
}

export const mapCampaignDataSettingsToProspectSettingsRequest = (
  campaignData: ICampaignModel,
  titleOption: ImageTextOption,
  descriptionOtion: ImageTextOption,
  phoneNumberOption: ImageTextOption,
  titleOverride: string,
  descriptionOverride: string,
  phoneNumberOverride: string
): IProspectSettingsRequest => {
  const {
    data: { root },
    settings: { facebookPage, landingPage, header, displayHeader, phoneNumberSetting},
  } = campaignData
  return {
    facebookPage: facebookPage,
    landingPage: landingPage ? landingPage : root.link,
    header: header ?? displayHeader,
    displayHeader: displayHeader,
    overrideImageTitles: titleOption === ImageTextOption.Unique ? "" : titleOverride,
    overrideImageDescriptions: descriptionOtion === ImageTextOption.Unique ? "" : descriptionOverride,
    overrideImagePhoneNumbers: phoneNumberOption === ImageTextOption.Unique ? "" : phoneNumberOverride,
    phoneNumberSetting  
  }
}
export const updateSettings = (prospectId: string, campaignId: string, data: IProspectSettingsRequest) =>
  put(`/Prospect/${prospectId}/campaign/${campaignId}/settings`, data)

export const updateImages = (prospectId: string, campaignId: string, data: IProspectDataImage[]) =>
  put(`/Prospect/${prospectId}/campaign/${campaignId}/images`, data)

export const updateTargeting = (prospectId: string, campaignId: string, data: ITargeting) =>
  put(`/Prospect/${prospectId}/campaign/${campaignId}/targeting`, data)

export const getById = (prospectId: string) => get<IProspectCampaignRequest & IProspectViewModel>(`Prospect/${prospectId}`)

export const getMediaLibrary = (prospectId: string) => get<IProspectClientAssetResponse[]>(`Prospect/${prospectId}/medialibrary`)

export const updateReportRecipients = (prospectId: string, campaignId: string, data: string[]) =>
  put(`/Prospect/${prospectId}/campaign/${campaignId}/reportRecipients`, data)

export const updateCampaignManagers = (prospectId: string, campaignId: string, data: string[]) =>
  put(`/Prospect/${prospectId}/campaign/${campaignId}/campaignManagers`, data)

export const updateBudget = (prospectId: string, campaignId: string, budgetId: string, data: ISetBudgetRequest) =>
  put(`/Prospect/${prospectId}/campaign/${campaignId}/budget/${budgetId}`, data)

export const commitBudget = (prospectId: string, campaignId: string, budgetId: string, data: ICommitBudgetRequest) =>
  post(`/Prospect/${prospectId}/campaign/${campaignId}/budget/${budgetId}/commit`, data)

export const extendBudget = (prospectId: string, campaignId: string, data: IExtendBudgetRequest) =>
  post(`/Prospect/${prospectId}/campaign/${campaignId}/budget`, data)

export const uploadImage = (id: string, file: FormData) =>
  post<IProspectAssetResponse>(`/Prospect/${id}/medialibrary`, file, {
    headers: { "Content-Type": "multipart/form-data" },
  })

export const updateCampaign = (prospectId: string, campaignId: string) =>
  post(`/Prospect/${prospectId}/campaign/${campaignId}/update`)
