import { ICampaign } from "entities/interfaces"
import { useIntl } from "react-intl"

interface ICampainCounterCellProps {
  campaigns: ICampaign[]
}

const CampaignCounterCell = ({ campaigns }: ICampainCounterCellProps) => {
  const { formatMessage } = useIntl()
  return (
    <div
      title={
        campaigns.length > 0
          ? formatMessage({ id: "hint.usedBy" }, { campaigns: `${campaigns.map((campaign: ICampaign) => campaign.name)}` })
          : formatMessage({ id: "hint.notInUse" })
      }
    >
      <span>{campaigns.length}</span>
    </div>
  )
}
export default CampaignCounterCell
