import { TextField, Tooltip } from "@mui/material"
import { Controller, FieldValues } from "react-hook-form"
import { Control } from "react-hook-form"
import { useIntl } from "react-intl"

interface IControlTextFieldProps<T extends FieldValues = any> {
  name: string
  control: Control<T>
  disabled?: boolean
  placeholder?: string
  label: string
  type?: string
  fullWidth?: boolean
  className?: string
  required?: boolean
  defaultValue?: string | number
  multiline?: boolean
  rows?: number
  tooltipText?: string
  helperText?: string
  errorMessage?: string
  shrink?: boolean
  onBlur?: (value: string) => void
}

const ControlTextField = ({
  name,
  control,
  disabled = false,
  placeholder = "",
  label,
  type = "text",
  fullWidth = true,
  className = "",
  required = false,
  defaultValue,
  multiline = false,
  rows = 0,
  tooltipText = "",
  helperText,
  errorMessage,
  shrink = undefined,
  onBlur,
}: IControlTextFieldProps) => {
  const { formatMessage } = useIntl()
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ field: { onChange, value }, fieldState: { error } }) => {
        return (
          <Tooltip title={tooltipText}>
            <TextField
              required={required}
              className={className}
              fullWidth={fullWidth}
              variant="outlined"
              type={type}
              value={value}
              label={label}
              placeholder={placeholder}
              multiline={multiline}
              rows={rows}
              onChange={onChange}
              error={!!error}
              disabled={disabled}
              helperText={error ? errorMessage || formatMessage({ id: `${error.message}` }) : helperText || ""}
              InputLabelProps={{
                shrink: shrink,
              }}
              onBlur={(e) =>
                onBlur ? onBlur((e.target as HTMLInputElement).value) : onChange((e.target as HTMLInputElement).value)
              }
            />
          </Tooltip>
        )
      }}
    />
  )
}
export default ControlTextField
