import { IMoney } from "../../entities/interfaces"
import Money from "./Money"

interface IBudgetsMoneyProps {
  budgets: IMoney[]
}

const BudgetsMoney = ({ budgets }: IBudgetsMoneyProps) => (
  <>
    {budgets.map((budget: IMoney, i: number) => (
      <Money key={i} money={budget} />
    ))}
  </>
)

export default BudgetsMoney
