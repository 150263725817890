import au from "../assets/translations/au"
import en from "../assets/translations/en"
import sv from "../assets/translations/sv"
import no from "../assets/translations/no"
import nl from "../assets/translations/nl"
import fi from "../assets/translations/fi"
import de from "../assets/translations/de"
import da from "assets/translations/da"

interface ILocale {
  locale: string
  messages: { [key: string]: string }
}

export type Locales = keyof typeof locales
export type Currencies = "AUD" | "NOK" | "SEK" | "NZD" | "EUR" | "DKK"

const locales = {
  en: { locale: "en", messages: en },
  sv: { locale: "sv", messages: sv },
  no: { locale: "no", messages: no },
  nl: { locale: "nl", messages: nl },
  de: { locale: "de", messages: de },
  fi: { locale: "fi", messages: fi },
  da: { locale: "da", messages: da },
  "en-au": { locale: "en-au", messages: { ...en, ...au } },
  "en-nz": { locale: "en-nz", messages: { ...en, ...au } },
}

export interface ISupportedLocales {
  id: Locales
  name: Locales
}
export interface ISupportedCurrencies {
  id: Currencies
  name: Currencies
}

export const supportedLocales: ISupportedLocales[] = [
  { id: "en", name: "en" },
  { id: "en-au", name: "en-au" },
  { id: "en-nz", name: "en-nz" },
  { id: "no", name: "no" },
  { id: "sv", name: "sv" },
  { id: "nl", name: "nl" },
  { id: "de", name: "de" },
  { id: "fi", name: "fi" },
  { id: "da", name: "da" },
]

export const supportedCurrencies: ISupportedCurrencies[] = [
  { id: "AUD", name: "AUD" },
  { id: "NZD", name: "NZD" },
  { id: "SEK", name: "SEK" },
  { id: "NOK", name: "NOK" },
  { id: "EUR", name: "EUR" },
  { id: "DKK", name: "DKK" },
]

export const validateLocaleFallbackEnglish = (localeCode: Locales): ILocale => {
  localeCode = locales[localeCode] ? localeCode : "en"
  return locales[localeCode]
}
