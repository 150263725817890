/* eslint-disable @typescript-eslint/no-unused-vars */

import { action, observable, computed, makeObservable } from "mobx"
import { IAsset, IClient, IFacebookData } from "entities/interfaces"
import * as API from "api"
import { ProspectCampaignRequest } from "entities/models/Prospect"
import { calculateImpressionsCpm } from "utils/calculations"
import { CallToActionType, ReferenceType } from "entities/enums"
import { validateLocationsOverlap } from "utils/locationHelpers"
import { AdProspectType, IOrderDataStore, OrderStepView, OrderStore } from "./OrderStore"
import { prepareFacebookName } from "utils/url"
import { destroyToken, setTokenAndRefreshToken, validateToken } from "utils/tokenHelpers"
import queryString from "query-string"
import { isProspectUser } from "utils/user_tenant_helpers"
import { IExtendBudgetRequest } from "api/prospect"
import axios from "axios"
import toast from "react-hot-toast"
/* eslint-enable @typescript-eslint/no-unused-vars */

export class OrderProspectStore implements IOrderDataStore {
  @observable public client: IClient
  @observable public prospect: ProspectCampaignRequest
  @observable public facebookData: IFacebookData

  @observable public orderStore: OrderStore
  @observable public validatingLocations: boolean

  constructor(orderStore: OrderStore) {
    makeObservable(this)
    this.orderStore = orderStore
  }

  @action public async init(
    clientIdSlug: string,
    prospectId: string,
    search: string,
    adProspectType: AdProspectType,
    startOnLastStep: boolean
  ) {
    if (adProspectType === AdProspectType.extendProspect) {
      this.orderStore.currentOrderStep = OrderStepView.budgetStep
    }

    try {
      const prospectResponse = await API.Prospect.getById(prospectId)
      this.prospect = new ProspectCampaignRequest(prospectResponse.data)

      if (prospectResponse.data.campaigns[0].budgets[0].orderedAt && adProspectType === AdProspectType.newProspect) {
        this.orderStore.alreadyOrdered = true
      }

      const clientResponse = await API.Client.getBySlug(clientIdSlug)
      this.client = clientResponse.data

      const prospectMedialibrary = await API.Prospect.getMediaLibrary(prospectId)
      const mediaLibrary = prospectMedialibrary.data

      mediaLibrary.map((media) =>
        this.prospect.configuration.availableImages.push({
          title: "",
          description: "",
          phoneNumber: null,
          url: media.path,
          templateData: media.templateData,
          hasVideoUrl: Boolean(media.videoUrl),
          category: media.category,
        })
      )

      const facebookPageName = prepareFacebookName(prospectResponse.data.campaigns[0].settings.facebookPage)
      const allFacebookResponse = await Promise.all([
        API.Campaign.resolveCampaignFacebookPage({ page: facebookPageName }),
        API.Template.getById(this.prospect.source.templateId),
      ])
      this.facebookData = allFacebookResponse[0].data
      this.facebookData.callToAction = allFacebookResponse[1].data.channels.facebook?.callToAction || CallToActionType.NoButton
      this.prospect.channels = allFacebookResponse[1].data.channels
    } catch (error: unknown) {
      if (axios.isAxiosError(error)) {
        if (!error.response || error.response?.status >= 500) throw new Error("Network Error")
      }
      const errorMessage = `${JSON.stringify(error, Object.getOwnPropertyNames(error))}\r\n${error}`
      toast.error(`${errorMessage}`, { duration: Infinity })
    }
  }

  @action public async onPutProof() {
    try {
      await API.Prospect.updateImages(this.prospect.id, this.prospect.campaignData.id, this.prospect.campaignData.images)

      const settingsRequest = API.Prospect.mapCampaignDataSettingsToProspectSettingsRequest(
        this.prospect.campaignData,
        this.prospect.imageTitleOption,
        this.prospect.imageDescriptionOption,
        this.prospect.imagePhoneNumberOption,
        this.prospect.campaignData.settings.overrideImageTitles,
        this.prospect.campaignData.settings.overrideImageDescriptions,
        this.prospect.campaignData.settings.overrideImagePhoneNumbers
      )
      await API.Prospect.updateSettings(this.prospect.id, this.prospect.campaignData.id, settingsRequest)
    } catch (error) {
      this.orderStore.currentOrderStep = OrderStepView.proofStep
      //todo: maybe we should give more error back? what happened
      this.orderStore.couldNotOrderProspect = true
    }
  }

  @action public async onPutTargeting() {
    try {
      await API.Prospect.updateTargeting(this.prospect.id, this.prospect.campaignData.id, this.prospect.campaignData.targeting)
    } catch (error) {
      //todo: maybe we should give more error back? what happened
      this.orderStore.currentOrderStep = OrderStepView.targetingStep
      this.orderStore.couldNotOrderProspect = true
    }
  }

  @action public async onBuyExtendOrder() {
    try {
      const {
        id,
        campaignData,
        reportRecipients,
        campaignManagers,
        amount,
        start,
        end,
        reference,
        linkedInAmount,
        customReferences,
      } = this.prospect

      const budgetRequest: IExtendBudgetRequest = {
        amount: amount,
        linkedInAmount: linkedInAmount,
        start: start,
        end: end,
        reference: reference,
        customReferences: customReferences,
      }
      await API.Prospect.updateReportRecipients(id, campaignData.id, reportRecipients)

      if (this.prospect.source.canAddCampaignManagers) {
        await API.Prospect.updateCampaignManagers(id, campaignData.id, campaignManagers)
      }

      const extentBudgetResponse = await API.Prospect.extendBudget(id, campaignData.id, budgetRequest)

      if (extentBudgetResponse.data !== "") {
        this.orderStore.errorMessage = extentBudgetResponse.data as string
        this.orderStore.couldNotOrderProspect = true
      } else {
        if (isProspectUser(this.orderStore.rootStore.currentUser)) {
          destroyToken()
        }
        this.orderStore.prospectOrderDone = true
      }
    } catch (error) {
      this.orderStore.couldNotOrderProspect = true
    }

    this.orderStore.loading = false
  }

  @action public async onBuyNewOrder() {
    this.orderStore.loading = true
    const {
      id,
      campaignData,
      reportRecipients,
      campaignManagers,
      amount,
      start,
      end,
      reference,
      linkedInAmount,
      customReferences,
    } = this.prospect
    try {
      await API.Prospect.updateReportRecipients(id, campaignData.id, reportRecipients)

      if (this.prospect.source.canAddCampaignManagers) {
        await API.Prospect.updateCampaignManagers(id, campaignData.id, campaignManagers)
      }

      const updateBudgetErrorMessage = await API.Prospect.updateBudget(
        id,
        campaignData.id,
        this.prospect.campaignData.budgets[0].id,
        {
          amount,
          start,
          end,
          linkedInAmount,
        }
      )
      if (updateBudgetErrorMessage.data !== "") {
        this.orderStore.errorMessage = "End date is past allowed end date"
        this.orderStore.couldNotOrderProspect = true
      } else {
        await API.Prospect.commitBudget(id, campaignData.id, this.prospect.campaignData.budgets[0].id, {
          reference,
          customReferences,
        })

        this.orderStore.prospectOrderDone = true
        if (isProspectUser(this.orderStore.rootStore.currentUser)) {
          destroyToken()
        }
      }
    } catch (error) {
      this.orderStore.couldNotOrderProspect = true
      this.orderStore.loading = false
    }
    this.orderStore.loading = false
  }
  @action public async onEditOrder() {
    const { id, campaignData, reportRecipients } = this.prospect
    try {
      await API.Prospect.updateReportRecipients(id, campaignData.id, reportRecipients)
      await API.Prospect.updateCampaign(id, campaignData.id)
      this.orderStore.prospectOrderDone = true
      if (isProspectUser(this.orderStore.rootStore.currentUser)) {
        destroyToken()
      }
    } catch (error) {
      this.orderStore.couldNotOrderProspect = true
      this.orderStore.loading = false
    }
    this.orderStore.loading = false
  }

  @action public async onBuyOrder() {
    this.orderStore.loading = true
    const isProspectReferenceFieldValid = this.validateProspectReferenceField()
    const areCustomFieldsValid = this.validateCustomFields()
    if (isProspectReferenceFieldValid && areCustomFieldsValid) {
        try {
          switch (this.orderStore.adProspectType) {
            case AdProspectType.extendProspect:
              await this.onBuyExtendOrder()
              break
            case AdProspectType.newProspect:
              await this.onBuyNewOrder()
              break
            case AdProspectType.editProspect:
              await this.onEditOrder()
              break
            default:
              throw new Error("Not valid AdProspectType")
          }
        } catch (error) {
          this.orderStore.couldNotOrderProspect = true
          this.orderStore.loading = false
        }
    } else {
      this.orderStore.loading = false
    }
  }

  @action public validateProspectReferenceField(): boolean {
    if (this.prospect.configuration.referenceSettings.type === ReferenceType.Required && this.prospect.reference === "") {
      this.orderStore.referenceRequired = true
      return false
    } else {
      this.orderStore.referenceRequired = false
      return true
    }
  }

  @action public validateCustomFields(): boolean {
    const { customReferences, configuration } = this.prospect

    var validateCustomReferences = Object.keys(configuration.customReferenceFields).map((key: string) => {
      if (configuration.customReferenceFields[key].type === ReferenceType.Required && !customReferences[key]) {
        configuration.customReferenceFields[key].valid = false
        this.orderStore.customReferenceRequired = false
        return false
      }
      configuration.customReferenceFields[key].valid = true
      this.orderStore.customReferenceRequired = true
      return true
    })
    return validateCustomReferences.every((x) => x)
  }

  @action public async validateLocationsInitial() {
    const locations = this.prospect.campaignData.targeting.locations
    if (locations.length) {
      this.validatingLocations = true
      this.orderStore.locationsValid = await validateLocationsOverlap(locations)
      this.validatingLocations = false
    }
  }

  /* VALIDATION FUNCTIONS END */

  @computed get targetedImpressions(): number {
    return this.prospect ? calculateImpressionsCpm(this.prospect.amount, this.prospect.configuration.currency.cpm) : 0
  }

  @computed get totalBudget(): number {
    return this.prospect ? Number(this.prospect.amount) + Number(this.prospect.linkedInAmount) : 0
  }
}
