import { AxiosResponse } from "axios"

export const sleep = (sleepTimeMS: number) => new Promise((resolve) => setTimeout(resolve, sleepTimeMS))

export const retryFn = async (fn: () => Promise<any>, sleepTimeMS: number, retries: number): Promise<any> => {
  try {
    const result = await fn()
    return result
  } catch (err) {
    if (retries === 1) throw err
    if (err === "OVER_QUERY_LIMIT") {
      await sleep(sleepTimeMS)
      return await retryFn(fn, sleepTimeMS, retries - 1)
    }
  }
}

interface Ifn<T> {
  (): Promise<AxiosResponse<T, any>>
}
export const unwrap =
  <T>(fn: Ifn<T>) =>
  async (): Promise<T> => {
    return (await fn()).data
  }
