import { ICampaignDefaults, ICampaignsStatistics, ITenantListModel, ITheme, IVat, PinMeToSettings } from "entities/interfaces"
import { get, put, post } from "api/restClient"
import { BillingType } from "api/client"
import { IReseller } from "api/reseller"

export interface IGroup {
  id: string
  name: string
  currencyCode: string
  vat: IVat
  languageCode: string
  webSite: string | null
  externalId: string
  facebookPageId?: string | null
  theme: ITheme
  ownedCampaignStats: ICampaignsStatistics
  aggregatedCampaignStats: ICampaignsStatistics
  campaignDefaults: ICampaignDefaults
  tenant?: ITenantListModel
  reseller?: IReseller
  metadata: Record<string, string>
  billingType: BillingType
  settings: IGroupSettings
  fallbackFacebookPageId: string | null
  fallbackLinkedInPageId?: string | null
  pinMeToSettings?: PinMeToSettings
  linkedInPageId?: string
  linkedInAdAccountId?: string
}

export interface IGroupRequest {
  name: string
  externalId?: string | null
  facebookPage?: string | null
  resellerId?: string | null
  tenantId?: string | null
  currencyCode: string
  theme?: { primaryColor?: string }
  languageCode: string
  campaignDefaults: ICampaignDefaults
  webSite?: string | null
  settings: IGroupSettings
  billingType: BillingType
  fallbackFacebookPageId?: string | null
  fallbackLinkedInPageId?: string | null
  metadata: Record<string, string>
  linkedInPageId?: string | null
  linkedInAdAccountId?: string | null
}

export enum GroupMediaLibrarySettings {
  EverythingAvailable = "everythingAvailable",
  OnlyClientOrHqWhenAvailable = "onlyClientOrHqWhenAvailable",
}

export interface IGroupSettings {
  magicTokenUsages?: number | null
  mediaLibrarySettings: GroupMediaLibrarySettings
  allowPublicClientMetadata: boolean
}

export const getAll = () => get<IGroup[]>("Group")

export const getById = (id: string) => get<IGroup>(`Group/${id}`)

export const updateGroupIdOnClient = (id: string, clientId: string) => put(`/Group/${id}/clients/${clientId}`)

export const create = (data: IGroupRequest) => post<IGroup>("Group", data)

export const edit = (id: string, data: IGroupRequest) => put(`/Group/${id}`, data)

export const enablePinMeTo = (groupId: string, data: PinMeToSettings) => put(`/Group/${groupId}/pinmeto/enable`, data)

export const disablePinMeTo = (groupId: string, data: PinMeToSettings) => put(`/Group/${groupId}/pinmeto/disable`, data)
