export default {
  "public.targetGroup": "Kohderyhmä",
  "public.adCorrection": "Todiste",
  "public.adBudget": "Osta",
  "public.sponsored": "Sponsoroitu",
  "public.swipe": "Swaippaa",
  "public.uniquePerImage": "Jos ainutlaatuinen kuva on valittu, selaa alas vaihtaaksesi tekstiä",
  "public.gender&age": "Sukupuoli & ikä",
  "public.emailExample": "esimerkki@email.com",
  "public.startDate": "Aloituspäivä",
  "public.endDate": "Päättymispäivä",
  "public.topMedia": "Huippumedia",
  "public.social": "Sosiaalinen",
  "public.display": "Näyttö",
  "public.webTv": "Verkko-TV",
  "public.video": "Video",
  "public.digitalScreen": "Digitaalinen näyttö (DOOH)",
  "public.devices": "Laitteet",
  "public.media": "Media",
  "public.url": "URL",
  "public.clicks": "klikkaukset",
  "public.createLink": "Luo linkki",
  "public.generateReport": "Luo raportti",
  "public.showFullReport": "Näytä koko raportti",
  "public.impressions": "Vaikutelmat",
  "public.reach": "Tavoitettavuus",
  "public.reachPerPerson": " beses/Tao",
  "public.ctr": "CTR",
  "public.ctrReach": "CTR-tavoitettavuus",
  "public.ctrImpressions": "CTR-vaikutelmat",
  "public.channels": "Kanavat",
  "public.mobile": "Mobiili",
  "public.desktop": "Työpöytä",
  "public.fbMobile": "Facebook-mobiili",
  "public.fbDesktop": "Facebook-työpöytä",
  "public.mediaBudget": "Media-budjetti",
  "public.geoPlaces": "Maantieteellisesti kohdennetut sijainnit",
  "public.reportRecipient": "Raportin vastaanottajat",
  "public.campaignManagers": "Kampanjan managerit",
  "public.newAdImages": "Anna kuvat mainoksellesi",
  "public.currentAdImages": "Nykyiset kuvat mainoksellesi",
  "public.adLandingPage": "Aloitussivu",
  "public.report": "Raportti",
  "public.orderProofStep": "Valitse tekstiä",
  "public.orderTargetingStep": "Valitse maantieteellisesti kohdennetut sijainnit & yleisö",
  "public.orderLocationStep": "Valitse maantieteellisesti kohdennetut sijainnit",
  "public.orderBudgetStep": "Valitse budjetti, aikataulu & koontinäytön vastaanottajat",
  "public.adTextAboveImage": "Kuvan yläpuolella oleva mainoksen kuvaus",
  "public.adTitleBelowImage": "Kuvan alapuolella oleva otsikko",
  "public.descBelowImage": "Kuvan alapuolella oleva kuvaus",
  "public.otherImages": "Muut mainoksessasi käytettävät saatavilla olevat kuvat",
  "public.costCenter": "Kustannuskeskus",
  "public.chooseMediaBudget": "Valitse mediabudjetti",
  "public.addLinkedInJobPost": "Valitse mediabudjetti LinkedIn-työpaikkajulkaisulle",
  "public.linkedInAmount": "LinkedIn-määrä",
  "public.noLinkedJobPost": "Ei LinkedIn-työpaikkajulkaisua",
  "public.purchase": "Osta",
  "public.purchaser": "Ostaja",
  "public.purchased": "Ostettu",
  "public.perDayCost": "{value} SEK päivässä",
  "public.sendNewLogin": "Lähetä uusi kirjautuminen",
  "public.extendAd": "Jatka mainosta",
  "public.adTotal": "Yhteensä:",
  "public.targetGroupControlledAds": "Kohdistetut vaikutelmat",
  "public.age": "Ikä",
  "public.gender": "Sukupuoli",
  "public.man": "Mies",
  "public.men": "Miehet",
  "public.woman": "Nainen",
  "public.women": "Naiset",
  "public.reference": "Viite",
  "public.like": "Tykkää",
  "public.comment": "Kommentoi",
  "public.share": "Jaa",
  "public.comments": "{value} kommenttia",
  "public.shares": "{value} jakamiskertaa",
  "public.minMaxAge": "Vähintään {min}. Enintään {max}",
  "public.openNewWindow": "Avaa uudessa ikkunassa",
  "public.order": "Tee tilaus",
  "public.next": "Seuraava",
  "public.previous": "Edellinen",
  "public.adPeriod": "Aikajakso",
  "public.companyName": "Yrityksen nimi",
  "public.uploadImage": "Lataa kuva",
  "public.asap": "Aloita mahdollisimman pian",
  "public.totalValue": "Yhteensä {value}",
  "public.lastShowDate": "Päättymispäivä havaittu oletuksena",
  "public.createNewAdMultiEmail":
    "Määritä mahdolliset ylimääräiset sähköpostiosoitteet, joihin haluat saada kampanjan koontinäytön. Voit lisätä useita sähköpostiosoitteita.",
  "public.hintCampaignManagers":
    "Määritä mahdolliset ylimääräiset sähköpostiosoitteet, joiden haluat olevan valtuutettuja jatkaa mainosta. Voit lisätä useita sähköpostiosoitteita.",
  "public.addNewEmail": "Lisää sähköpostiosoite. Voit lisätä useita sähköpostiosoitteita",
  "public.shortTextRecomended":
    "Pidä asiat ytimekkäänä - suosittelemme enintään {recomendedLength} merkkiä. Se on tällä hetkellä {value} merkkiä.",
  "public.linkedInReporting": "Emme ilmoita automaattisia kohdistettuja mainoksia LinkedInistä",
  "public.vatPrices": "Hinnat {vatType} {vatSuffix}",
  "public.vatPricesFull": "Hinnat {vatType} {vatRate}% {vatSuffix}",
  "public.validatingLocations": "Varmennetaan geolokaatioita",
  "public.tags": "Lisää useita tunnisteita, kirjoita tekstiä ja paina Enter.",
  "public.tenantMediaLocale": "Lisää alue kirjoittamalla tekstiä ja painamalla Enter tai valitse vaihtoehdoista.",
  "public.tenantMediaTags": "Lisää useita tunnisteita, kirjoita tekstiä ja paina Enter tai valitse vaihtoehdoista.",
  "public.tenantMediaCategories": "Lisää tekstiä ja paina Enter tai valitse vaihtoehdoista.",
  "public.newAdConfirmation": "Saat tilausvahvistuksen antamaasi sähköpostiosoitteeseen.",
  "public.adCreated": "Mainos on luotu. Voit nyt sulkea tämän ikkunan.",
  "public.minBudget": "Vaikutelmia on oltava vähintään {value} päivässä tilauksen tekemiseksi.",
  "public.dragAndDropImages": `Määritä tilaus ja se, mitä kuvia näytetään "vetämällä ja pudottamalla"`,
  "public.addTargetGroupLocation": "Lisää geolokaatiokohteet etsimällä paikkoja tai osoitteita..",
  "public.more": "...Lisää",
  "public.readMore": "...Lue lisää",
  "public.fileDrop": "Napsauta valitaksesi tiedostoja tai raahaa ne tähän ladataksesi",
  "public.orders": "Tilaukset",
  "public.exclude": "Pl.",
  "public.include": "Ml.",
  "public.vatSuffix": "ALV",
  "public.vat": "{vatType} {vatRate}% {vatSuffix}",
  "public.vatNoRate": "{vatType} {vatSuffix}",
  "public.loading": "Ladataan",
  "public.newLoginSent": "Uudet kirjautumistiedot lähetetty",
  "public.errorLogin": "Virhe, ota yhteyttä",
  "public.requestNewLogin": "Pyydä uusia kirjautumistietoja sähköpostitse",
  "public.noImageSelected": "Kuvaa ei valittu",
  "public.multiImageCategories": "Valitse yksi tai useampi kuvakategoria",
  "public.infoSpecialAdCategory": "Demografiset asetukset on lukittu Metan (Facebook & Instagram) mainoskäytännön mukaisesti",
  "public.edit": "Muokkaa",
  "public.success": "Onnistui!",
  "public.orderPlaced": "Kiitos tilauksestasi!",
  "public.hint.image": "Hyväksytyt tiedostotyypit: JPG/PNG/BMP",
  "public.hint.font": "Hyväksytyt tiedostotyypit: .ttf/.woff",
  "public.youTube": "YouTube",
  "public.date": "Päivämäärä",
  "public.unauthorized": "Valtuuttamaton: Pääsy evätty.",
  "tooltip.prefilledField":
    "Jos haluat ohittaa oletusarvoisen esitäytetyn kentän: Viite (viitenumero, kustannuspaikka tai henkilö)",
  "title.prefilledField": "Esitäytetty kustannuspaikka",
  "imageTextOption.same": "Sama kaikille",
  "imageTextOption.unique": "Ainutlaatuinen kuvaa kohden",

  "landingPageSetting.uneditable": "Muokamaton",
  "landingPageSetting.same": "Sama kaikille",
  "landingPageSetting.unique": "Ainutlaatuinen",

  "common.noCategory": "Ei luokkaa",
  "common.all": "Kaikki",
  "common.inactive": "Passiivinen",

  "calltoaction.NO_BUTTON": "Ei painiketta",
  "calltoaction.BUY_NOW": "Osta nyt",
  "calltoaction.APPLY_NOW": "Käytä nyt",
  "calltoaction.BOOK_TRAVEL": "Varaa matkustus",
  "calltoaction.WATCH_MORE": "Katso lisää",
  "calltoaction.CONTACT_US": "Ota yhteyttä",
  "calltoaction.DOWNLOAD": "Lataa",
  "calltoaction.GET_OFFER": "Hanki tarjous",
  "calltoaction.GET_QUOTE": "Hanki tarjous",
  "calltoaction.SIGN_UP": "Rekisteröidy",
  "calltoaction.SUBSCRIBE": "Tilaa",
  "calltoaction.INTERESTED": "Kiinnostunut",
  "calltoaction.GET_SHOWTIMES": "Hanki aloitusajat",
  "calltoaction.REQUEST_TIME": "Pyyntöaika",
  "calltoaction.LEARN_MORE": "Lue lisää",
  "calltoaction.CALL_NOW": "Soita nyt",

  "error.required": "Tämä kenttä on pakollinen",
  "error.cpm": "Cpm:n on oltava numero ja arvon on oltava 65-900",
  "error.number": "Tämän kentän täytyy olla numero",
  "error.chooseOneGender": "* Vähintään yksi sukupuoli",
  "error.dailyBudgetLow": "Mediabudjetti on liian matala. Nosta mediabudjettia tai lyhennä kampanjan aikataulua.",
  "error.email": "Anna kelvollinen sähköposti",
  "error.generic": "Jokin meni pieleen, yritä uudelleen",
  "error.imageUploadError": "Kuvien tallentaminen epäonnistui",
  "error.oneTargetGroupArea": "Vähintään yksi geolokaatio",
  "error.locationOverlap": "Geolokaatiot eivät voi olla päällekkäisiä",
  "error.noFacebookPage": "Syötä Facebook-sivun URL jatkaaksesi",
  "error.invalidFacebookPage": "Facebook-sivu ei ole oikein, tarkista oikeinkirjoitus",
  "error.badLink": "Aloitussivu ei ole oikein, tarkista oikeinkirjoitus",
  "error.noLandingPage": "Sinun on syötettävä aloitussivusi",
  "error.noGenderChoosen": "Sukupuolta ei ole valittu",
  "error.noTargetGroupArea": "Sinun on syötettävä vähintään yksi geolokaatio",
  "error.locationNoLocationOverlap": "Sinun on syötettävä vähintään yksi geolokaatio eivätkä ne voi olla päällekkäisiä",
  "error.closeEndDate": "Päättymispäivä on vain 48h aloituspäivästä. Päättymispäivän on oltava 48h tulevaisuudessa",
  "error.orderCouldNotComplete": "Tilausta ei voitu käsitellä",
  "error.fileTobig": "Tiedosto on liian suuri (enintään 50 Mt), valitse toinen tiedosto",
  "error.notImageFile": "Tiedoston on oltava kuva, valitse toinen tiedosto",
  "error.couldNotExtendProspect": "Mainosta ei voitu jatkaa, yritä myöhemmin uudelleen.",
  "error.couldNotOrderAd": "Mainosta ei voitu tilata",
  "error.channelDistribution": "Kanavajakelun on oltava yhteensä 100 %",

  "error.errorMessageMoreMinute": "Minuutin on oltava suurempi kuin 0",
  "error.errorMessageLessMinute": "Minuuttia saa olla enintään 59",
  "error.errorMessageMoreHours": "Tuntien on oltava suurempia kuin 0",
  "error.errorMessageLessHours": "Tuntien on oltava enintään 23",

  "public.error.proofRequired": "Vaaditaan vähintään yksi kuva",
  "public.error.proofDisplayRequired": "Vähintään {value} kuvaa vaaditaan",
  "public.error.hasEmptyPhoneNumbers": "Puhelinnumero Kenttä ei saa olla tyhjä",
  "public.error.file-invalid-type": "Virheellinen tiedostotyyppi",
  "public.error.file-too-large": "tiedosto on liian suuri (enintään 50 Mt), valitse toinen tiedosto",
  "public.error.too-many-files": "Liian monta tiedostoa, valitse yksi",
  "public.error.pageNotFound": "Sivua ei löytynyt!",
  "public.error.pageNotFoundDescription": "Syötä kelvollinen URL tai ota yhteyttä tukeen.",
  "public.error.pageNetworkError": "Sisäinen palvelinvirhe",
  "public.error.pageNetworkErrorDescription": "Yritä myöhemmin uudelleen tai ota yhteyttä tukeen.",
  "public.error.landingPages": "Kaikkien aloitussivujen on oltava kelvollisia URL-osoitteita.",

  "common.timeInterval": "Käytä mukautettua väliä",
  "common.showDayAndTime": "Päivä ja aikaväli",
  "common.customDateAndTime": "Päivämäärä ja aika",
  "common.crawlingSpindleSpeed": "Karan nopeus",
  "common.customDaySpeed": "Viikonpäivät",
  "common.customHourSpeed": "Tunnit",
  "common.customMinuteSpeed": "Minuutit",
  "common.cronExpression": "Käytä CRON-lauseketta",
  "common.cronStatement": "TAI voit käyttää alla olevaa CRON-lauseketta",
  "common.pinMeTo": "PinMeTo",
  "common.pinMeToAdAccountId": "PinMeTo Mainostilin Tunnus",
  "common.pinMeToAppID": "PinMeTo Sovellustunnus",
  "common.pinMeToAppSecret": "PinMeTo Sovelluksen Salaisuus",
  "common.enable": "Ota käyttöönd",
  "common.disable": "Poista käytöstäd",
  "common.valid": "Valkoinen",
  "common.invalid": "Virheellinen",

  "confirm.useInactiveDataSourceOnCampaign":
    "Oletko varma, että haluat asettaa inaktiivisen datalähteen ({dataSource}) kampanjaan {campaign}?",

  "title.selectedDay": "Valittu päivä",
  "title.useCustomStaticCreatives": "Käytä mukautettuja staattisia mainoksia",

  "billingType.unspecified": "Määrittelemätön",
  "billingType.monthly": "Kuukausittain etukäteen",
  "billingType.monthlyArrears": "Kuukausittain jälkikäteen",
  "billingType.quarterly": "Neljännesvuosittain etukäteen",
  "billingType.semiYearly": "Puolivuosittain etukäteen",
  "billingType.yearly": "Vuosittain etukäteen",
  "billingType.onDemand": "Verkkokaupasta tilatessasi",
  "billingType.external": "Ulkoinen",

  "title.apiChannels": "Sallitut Api-kanavat",
  "title.enablePinMeTo": "Ota PinMeTo käyttöön",
  "title.pinMeToCampaign": "Aseta PinMeTo-kampanjaksi",
  "title.integratePinMeToCampaign":
    "Kampanja {integratedCampaign} on integroitu. Vahvista ohittaminen kampanjalla {campaignName}",
  "title.linkedInPageId": "LinkedIn-viitetunnus",
  "title.linkedInAdAccountId": "LinkedIn-mainostilin tunnus",
}
