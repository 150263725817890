import { ChannelProfile } from "api/campaign"
import { get } from "api/restClient"
import { VideoFormats, VideoGroupings } from "entities/enums"

export interface IVideo {
  id: string
  path: string
  duration: number
  grouping: VideoGroupings
  items: number
  format: VideoFormats
  tags: string[]
  changed: string
}

export interface IVideoCapability {
  formats: VideoFormats[]
  minDuration: number | null
  maxDuration: number | null
}

export interface IPlacementCapability {
  images: boolean
  video: IVideoCapability | null
}

export interface IPlacement {
  id: string
  name: string
  capability: IPlacementCapability
}

export const getVideos = () => get<IVideo[]>("/Video/manifests")

export const placement = () => get<Record<ChannelProfile, IPlacement[]>>("/Placement")
