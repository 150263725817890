/* eslint-disable @typescript-eslint/no-unused-vars */
import { observable, action, computed, makeObservable } from "mobx"
/* eslint-enable @typescript-eslint/no-unused-vars */

interface ValidationObject<T> {
  errorState: T
}

export class ErrorModel<T> {
  @observable private validationObject: ValidationObject<T>

  constructor(errorState: T) {
    makeObservable(this)
    this.validationObject = observable({ errorState: errorState })
  }

  @computed get errorState(): T {
    return this.validationObject.errorState
  }

  @action onChange(key: string, error: boolean | Promise<boolean>, nestedProperty?: string): void {
    nestedProperty
      ? (this.validationObject.errorState[nestedProperty][key] = error)
      : (this.validationObject.errorState[key] = error)
  }
}
