import { differenceInMilliseconds } from "date-fns"

export const calculateCpm = (impressions: number, budget: number): number => budget / (impressions / 1000)

export const calculateImpressionsCpm = (budget: number, cpm: number): number => Math.floor(((budget / cpm) * 1000) / 500) * 500

export const calculateCtr = (clicks: number, impressions: number): string => {
  return impressions ? ((clicks / impressions) * 100).toFixed(2) : "0"
}

export const calculateImpressionCompletion = (deliveredImpressions: number, bookedImpressions: number): number => {
  return deliveredImpressions && bookedImpressions ? Math.floor((deliveredImpressions / bookedImpressions) * 100) : 0
}

export const MIN_THRESHOLD_DAILY_IMPS = 250

export const calcDailyImpressions = (startDate: Date, endDate: Date, startASAP: boolean, budget: number, cpm: number): number => {
  const impressionsCpm = calculateImpressionsCpm(budget, cpm)
  const durationInDays = differenceInMilliseconds(endDate, startASAP ? new Date() : new Date(startDate)) / 1000 / 60 / 60 / 24
  return Math.floor(impressionsCpm / durationInDays / 10) * 10
}

export const validateDailyImpressions = (dailyImpressions: number): boolean => {
  return dailyImpressions < MIN_THRESHOLD_DAILY_IMPS
}

export const abbreviateNumber = (value: number) => {
  const SI_POSTFIXES = ["", "k", "M", "G", "T", "P", "E"]

  // what tier? (determines SI prefix)
  var tier = Math.trunc(Math.log10(Math.abs(value)) / 3)

  // if zero, we don't need a prefix
  if (tier === 0) {
    return { value: value, suffix: "" }
  }

  // get postfix and determine scale
  var postfix = SI_POSTFIXES[tier]
  var scale = Math.pow(10, tier * 3)

  // scale the number
  var scaled = value / scale

  // format number and add postfix as suffix
  var formatted = scaled.toFixed(1) + ""

  // remove '.0' case
  if (/\.0$/.test(formatted)) {
    formatted = formatted.substr(0, formatted.length - 2)
  }

  return { value: parseFloat(formatted) || 0, suffix: postfix }
}
