import { Role } from "entities/enums"
import { get, put, post, destroy } from "api/restClient"
import { ITenantListModel } from "entities/interfaces"
import { IClient, IVat } from "entities/interfaces"
import { BillingType } from "api/client"
import { ITenantChannels, IEmailTemplate } from "api/tenant"

export interface ICreateUserRequest {
  email: string
  name: string
  role: Role
  userClients: string[]
  managerClients: string[]
  password?: string
  mobile?: string | null
  tenantId?: string | null
}

export interface IUpdateUserMobilNumberRequest {
  number: string
}

export interface IAddTenantFormTypes {
  userId: string,
  tenantId: string | null
}

export interface IUpdateUserRoleRequest {
  role: Role
}

export enum UserStatus {
  Active = "active",
  Inactive = "inactive",
}

export interface IUpdateUserStatusRequest {
  status: UserStatus
}

export interface IUserModel {
  id: string
  name: string
  email: string
  status: string
  mobileNumber: string
  role: Role
  tenant?: ITenantListModel
}

export interface IUser {
  id: string
  name: string
  isSandboxed: boolean
  defaultGroupId: string
  vat: IVat
  brokerageFee: number
  billingType: BillingType
  channels: ITenantChannels
  emailTemplate: IEmailTemplate | null
}

export interface IManagerModel extends IUserModel {
  isHq: boolean
}

export const getAll = () => get<IUserModel[]>("User")

export const getById = (id: string) => get<IUser>(`/User/${id}`)

export const getClients = (id: string) => get<IClient[]>(`/User/${id}/clients`)

export const create = (data: ICreateUserRequest) => post("User", data)

export const updateMobileNumber = (id: string, data: IUpdateUserMobilNumberRequest) => put(`User/${id}/mobile`, data)

export const updateUserTenant = (id: string, data: IAddTenantFormTypes) => put(`User/${id}/tenant`, data)

export const updateRole = (id: string, data: IUpdateUserRoleRequest) => put(`User/${id}/role`, data)

export const updateStatus = (id: string, data: IUpdateUserStatusRequest) => put(`User/${id}/status`, data)

export const resetPassword = (id: string) => post(`User/${id}/password/reset`)

export const addClientOnUser = (userId: string | null, clientId: string) =>
post(`/client/${clientId}/users/${userId}`)

export const destroyClient = (userId: string, clientId:string) => destroy(`client/${clientId}/user/${userId}`)