import { AdChannelType } from "entities/enums"
import * as React from "react"
import styled from "styled-components"
import { handleChannelIcon } from "utils/iconHelpers"
import FaIcon from "../FaIcon"

interface IChannelShareCellProps {
  className?: string
  channelShares: Record<AdChannelType, number>
}

const ChannelShareCell = ({ className, channelShares }: IChannelShareCellProps) => {
  return (
    <ChannelContainer className={className}>
      {Object.entries(channelShares).map((entry: [AdChannelType, number], i) => (
        <div key={i}>
          <FaIcon margin="0px 0px 0px 8px" prefix={handleChannelIcon(entry[0]).prefix} icon={handleChannelIcon(entry[0]).name} />
          <ChannelShare>{entry[1].toFixed(1)}</ChannelShare>
        </div>
      ))}
    </ChannelContainer>
  )
}
export default ChannelShareCell

const ChannelContainer = styled.span`
  margin-bottom: 8px;
`

const ChannelShare = styled.span`
  margin-left: 3px;
`
