import styled from "styled-components"
import { useIntl } from "react-intl"
import { selectUnit } from "utils/dateHelpers"

interface IDateCellProps {
  date: string
  user?: string
}

const DateCell = ({ date, user }: IDateCellProps) => {
  const { value, unit } = selectUnit(new Date(date))
  const { formatDate, formatTime, formatRelativeTime } = useIntl()
  return (
    <span>
      {date && date !== "0001-01-01T00:00:00Z" ? `${formatDate(date)} ${formatTime(date)}` : ""}
      <Ago>
        <br />
        {user && <span>{user} </span>} 
        {date && date !== "0001-01-01T00:00:00Z" ? formatRelativeTime(value, unit, { numeric: "auto" }) : ""}
      </Ago>
    </span>
  )
}
export default DateCell

const Ago = styled.span`
  color: hsla(210, 17%, 69%, 1);
  font-size: 0.833em;
`