import LoginForm from "./LoginForm"
import styled from "styled-components"
import { Grid } from "@mui/material"
import { useIntl } from "react-intl"
import { ILoginCredentials } from "api/token"
import { useSignIn } from "react-auth-kit"
import { useNavigate } from "react-router-dom"
import { decodeTokenToUser } from "utils/user_tenant_helpers"
import * as API from "api"
import { ErrorMessage } from "components/common"
import { useState } from "react"
import jwtDecode, { JwtPayload } from "jwt-decode"
import { refreshTokenExpiresIn } from "utils/tokenHelpers"
import { useMutation } from "react-query"

const Login = () => {
  const navigate = useNavigate()
  const { formatMessage } = useIntl()

  const [loginError, setLoginError] = useState(false)

  const signIn = useSignIn()

  const handleError = () => {
    setLoginError(true)
    setTimeout(() => {
      setLoginError(false)
    }, 2000)
  }
  const login = async (loginCredentials: ILoginCredentials) => API.Token.signIn(loginCredentials)

  const { isLoading, mutate: onLogin } = useMutation(login, {
    onError: () => {
      handleError()
    },
    onSuccess: (response) => {
      const user = decodeTokenToUser(response.data.token)
      signIn({
        token: response.data.token,
        expiresIn: jwtDecode<JwtPayload>(response.data.token).exp!,
        tokenType: "Bearer",
        authState: user,
        refreshToken: response.data.refreshToken,
        refreshTokenExpireIn: refreshTokenExpiresIn,
      })
      navigate("/")
    },
  })
  return (
    <Grid container>
      <Grid item xs={12}>
        <LoginContainer>
          <LoginWrapper>
            <LoginSiderBar>
            <LoginLogo src={`${window.tenant.logoUri}/logo-inverted.png`} />
            </LoginSiderBar>

            <LoginForm handleSignIn={onLogin} disabled={isLoading} />
          </LoginWrapper>
          {loginError && <ErrorMessage>{formatMessage({ id: "error.login" })}</ErrorMessage>}
        </LoginContainer>
      </Grid>
    </Grid>
  )
}

export default Login

const LoginContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
`

const LoginWrapper = styled.div`
  display: flex;
  background: #fff;
  border-radius: ${(p) => p.theme.borderRadius};
  box-shadow: ${(p) => p.theme.boxShadow};
  margin-bottom: ${(p) => p.theme.spacing};
  @media only screen and (max-width: 768px) {
    flex-direction: column;
  }
`

const LoginSiderBar = styled.div`
  background-color: #292f36;
  height: auto;
  border-top-left-radius: ${(p) => p.theme.borderRadius};
  border-bottom-left-radius: ${(p) => p.theme.borderRadius};
  width: 240px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 3em 0;
  @media only screen and (max-width: 768px) {
    width: 100%;
  }
`

const LoginLogo = styled.img`
  max-width: 190px;
  max-height: 150px;
`
