/* eslint-disable @typescript-eslint/no-unused-vars */
import { FilterLogic, FilterType } from "entities/enums"
import {
  IClient,
  IDataSource,
  IDataSourceEvaluation,
  IDataSourceListResponse,
  IDataSourceProvider,
  IDataSourceProviderFieldItem,
} from "entities/interfaces"
import { DataSourceProvidersRequest, IDataSourceProvidersRequest } from "entities/models/DataSource"
import { action, observable, makeObservable } from "mobx"
import { RootStore } from "mobxStores"
import { validate, validateAll } from "utils/validators"
import * as API from "api"
/* eslint-enable @typescript-eslint/no-unused-vars */

var omit = require("lodash.omit")

export enum ActiveDataSourceTab {
  DataSourceCode = "dataSourceCode",
  DataSourceEditor = "dataSourceEditor",
}

export class DataSourceStore {
  @observable public rootStore: RootStore
  @observable public client: IClient
  @observable public loading: boolean = true
  @observable public saving: boolean = false
  @observable public evaluatedData: IDataSourceEvaluation = { result: { root: {}, items: [] }, output: [] }
  @observable public evaluatingSpider: boolean = false
  @observable public activeDataSourceTab: ActiveDataSourceTab = ActiveDataSourceTab.DataSourceEditor
  @observable public createDataSourceProvidersRequest: DataSourceProvidersRequest
  @observable public editDataSourceProvidersRequest: DataSourceProvidersRequest
  @observable public clientDataSources: IDataSourceListResponse[] = []
  @observable public rootFields: string[] = ["title", "link", "description"]
  @observable public itemsFields: string[] = ["id", "title", "link", "description", "image", "geo", "category"]

  @observable public dataSourceFilterFields: string[] = []

  @observable public dataSourceSuccess: boolean = false

  constructor(rootStore: RootStore) {
    makeObservable(this)
    this.rootStore = rootStore
  }

  @action public async init(clientIdSlug: string, dataSourceId?: string) {
    this.loading = true
    try {
      const clientResponse = await API.Client.getBySlug(clientIdSlug)
      this.client = clientResponse.data
    } catch (error) {
      alert(error)
    }

    try {
      const dataSourcesResponse = await API.DataSource.getListByClientId(this.client.id)
      this.clientDataSources = dataSourcesResponse.data
    } catch (error) {
      alert(error)
    }

    if (dataSourceId) {
      try {
        const response = await API.DataSource.getById(dataSourceId)
        const mapedResponse: IDataSourceProvidersRequest = { ...response.data, providerCode: "" }
        this.editDataSourceProvidersRequest = new DataSourceProvidersRequest(mapedResponse)
        this.mapSpiderFields(response.data.providers)
        this.mapDataSourceFilterFields(response.data)
      } catch (error) {
        alert(error)
      }
    } else {
      this.createDataSourceProvidersRequest = new DataSourceProvidersRequest()
    }
    this.loading = false
  }

  @action public onChangeCreatableFilterFields(
    requestType: string,
    outerCollectionIndex: number,
    innerCollectionIndex: number,
    innerCollectionName: string,
    innerCollectionkey: string,
    value: string
  ): void {
    this[requestType].providers[outerCollectionIndex][innerCollectionName][innerCollectionIndex][innerCollectionkey] = value
    if (value && !this.dataSourceFilterFields.find((x) => x === value)) {
      this.dataSourceFilterFields.push(value)
    }
  }
  @action public mapDataSourceFilterFields(dataSource: IDataSource) {
    if (dataSource.data && dataSource.data.items && dataSource.data.items.length) {
      this.dataSourceFilterFields = Object.keys(dataSource.data.items[0])
    }
  }

  @action public mapSpiderFields(providers: IDataSourceProvider[]) {
    const rootKeys: string[] = []
    const itemKeys: string[] = []
    providers.forEach((provider: IDataSourceProvider) => {
      provider.fields.root.forEach((rootItem: IDataSourceProviderFieldItem) => {
        rootKeys.push(rootItem.key)
      })
      provider.fields.items.forEach((itemsItem: IDataSourceProviderFieldItem) => {
        itemKeys.push(itemsItem.key)
      })
    })
    this.rootFields = rootKeys
    this.itemsFields = itemKeys
  }

  @action public onChangeTab(tab: ActiveDataSourceTab, requestType: string): void {
    if (this.activeDataSourceTab === ActiveDataSourceTab.DataSourceCode && tab === ActiveDataSourceTab.DataSourceEditor) {
      if (this[requestType] && this[requestType].providerCode !== "") {
        this[requestType].fromJson({
          id: this[requestType].id,
          name: this[requestType].name,
          providers: JSON.parse(this[requestType].providerCode),
        })
        this.mapSpiderFields(JSON.parse(this[requestType].providerCode))
      }
    }

    if (this.activeDataSourceTab === ActiveDataSourceTab.DataSourceEditor && tab === ActiveDataSourceTab.DataSourceCode) {
      if (this[requestType]) {
        this[requestType].fromJson({
          id: this[requestType].id,
          name: this[requestType].name,
          providers: this[requestType].providers,
        })
      }
    }

    this.activeDataSourceTab = tab
  }

  @action public onCollapse(requestType: string, i: number): void {
    this[requestType].providers[i].collapsed = !this[requestType].providers[i].collapsed
  }

  @action public onChange(requestType: string, key: string, value: any): void {
    this[requestType][key] = value
  }

  @action public onBlur(requestType: string, key: string, validators: string, value: string) {
    this[requestType].errorModel.onChange(key, !validate(value, this[requestType][validators][key]))
  }

  @action public onChangeNullableObject(
    requestType: string,
    outerCollectionIndex: number,
    toogleKey: string,
    key: string,
    setKeyToValue: any
  ): void {
    this[requestType].providers[outerCollectionIndex][toogleKey] = !this[requestType].providers[outerCollectionIndex][toogleKey]
    if (this[requestType].providers[outerCollectionIndex][toogleKey]) {
      this[requestType].providers[outerCollectionIndex][key] = setKeyToValue
    } else {
      this[requestType].providers[outerCollectionIndex][key] = null
    }
  }

  @action public onChangeKeyValue(
    requestType: string,
    outerCollectionIndex: number,
    objectName: string,
    value: any,
    key: string,
    nestedProperty?: string
  ): void {
    if (nestedProperty) {
      this[requestType].providers[outerCollectionIndex][objectName][nestedProperty][key] = value
    } else {
      this[requestType].providers[outerCollectionIndex][objectName][key] = value
    }
  }

  @action public onChangeKeyKey(
    requestType: string,
    providerIndex: number,
    objectName: string,
    oldKey: string,
    newKey: any,
    nestedProperty?: string
  ): void {
    let newWordsObject = {}
    if (nestedProperty) {
      Object.keys(this[requestType].providers[providerIndex][objectName][nestedProperty]).forEach((key) => {
        if (key === oldKey) {
          let newPair = { [newKey]: this[requestType].providers[providerIndex][objectName][nestedProperty][oldKey] }
          newWordsObject = { ...newWordsObject, ...newPair }
        } else {
          newWordsObject = {
            ...newWordsObject,
            [key]: this[requestType].providers[providerIndex][objectName][nestedProperty][key],
          }
        }
      })
      this[requestType].providers[providerIndex][objectName][nestedProperty] = newWordsObject
    } else {
      Object.keys(this[requestType].providers[providerIndex][objectName]).forEach((key) => {
        if (key === oldKey) {
          let newPair = { [newKey]: this[requestType].providers[providerIndex][objectName][oldKey] }
          newWordsObject = { ...newWordsObject, ...newPair }
        } else {
          newWordsObject = {
            ...newWordsObject,
            [key]: this[requestType].providers[providerIndex][objectName][key],
          }
        }
      })
      this[requestType].providers[providerIndex][objectName] = newWordsObject
    }
  }

  @action public onAddKeyValue(requestType: string, providerIndex: number, objectName: string, nestedProperty?: string): void {
    if (nestedProperty) {
      var originalNestedObject = this[requestType].providers[providerIndex][objectName][nestedProperty]
      var newNestedKeyName = `newKey${originalNestedObject ? Object.keys(originalNestedObject).length + 1 : 0}`
      var newNestedKeyValue = { [newNestedKeyName]: "newValue" }
      this[requestType].providers[providerIndex][objectName][nestedProperty] = {
        ...originalNestedObject,
        ...newNestedKeyValue,
      }
    } else {
      var originalObject = this[requestType].providers[providerIndex][objectName]
      var newKeyName = `newKey${Object.keys(originalObject).length + 1}`
      var newKeyValue = { [newKeyName]: "newValue" }
      this[requestType].providers[providerIndex][objectName] = { ...originalObject, ...newKeyValue }
    }
  }

  @action public onRemoveKeyValue(
    requestType: string,
    providerIndex: number,
    objectName: string,
    key: string,
    nestedProperty?: string
  ): void {
    if (nestedProperty) {
      this[requestType].providers[providerIndex][objectName][nestedProperty] = omit(
        this[requestType].providers[providerIndex][objectName][nestedProperty],
        [key]
      )
    } else {
      this[requestType].providers[providerIndex][objectName] = omit(this[requestType].providers[providerIndex][objectName], [key])
    }
  }

  @action public onAddExclusion(requestType: string, outerCollectionIndex: number): void {
    this[requestType].providers[outerCollectionIndex].exclusions.push({
      key: "key",
      patterns: [],
    })
  }

  @action public onRemoveCollection(
    requestType: string,
    outerCollectionIndex: number,
    collectionName: string,
    collectionIndex: number
  ) {
    this[requestType].providers[outerCollectionIndex][collectionName].splice(collectionIndex, 1)
  }

  @action public onChangeInnerCollection(
    requestType: string,
    outerCollectionIndex: number,
    innerCollectionIndex: number,
    innerCollectionName: string,
    innerCollectionkey: string,
    value: string | number | boolean | Date
  ): void {
    this[requestType].providers[outerCollectionIndex][innerCollectionName][innerCollectionIndex][innerCollectionkey] = value
  }

  @action public onChangeInnerCollectionCreatable(
    requestType: string,
    outerCollectionIndex: number,
    innerCollectionIndex: number,
    innerCollectionName: string,
    innerCollectionkey: string,
    value: any
  ) {
    if (value.length) {
      let selection: string[] = []
      value.forEach((element: any) => {
        selection.push(element.value)
      })
      this[requestType].providers[outerCollectionIndex][innerCollectionName][innerCollectionIndex][innerCollectionkey] = selection
    } else {
      this[requestType].providers[outerCollectionIndex][innerCollectionName][innerCollectionIndex][innerCollectionkey] = []
    }
  }

  @action public onChangeProviderKey(
    requestType: string,
    outerCollectionIndex: number,
    key: string,
    value: any,
    nestedProperty?: string
  ): void {
    if (nestedProperty) {
      this[requestType].providers[outerCollectionIndex][key][nestedProperty] = value
    } else {
      this[requestType].providers[outerCollectionIndex][key] = value
    }
  }

  @action public onAddDataFields(requestType: string, outerCollectionIndex: number, fieldsCollection: string): void {
    this[requestType].providers[outerCollectionIndex].fields[fieldsCollection].push({
      key: "",
      selector: "",
      type: "text",
      replacements: [],
      transformScript: "",
    })
  }

  @action public onAddProvider(requestType: string): void {
    this[requestType].providers.push({
      url: "",
      container: "",
      containerTransformScript: "",
      userAgent: "",
      headers: {},
      formData: {},
      exclusions: [],
      filters: [],
      fields: { root: [], items: [] },
      requestVerificationToken: null,
      requestCredentialsToken: null,
      resolveCanonical: false,
      verifyRequest: false,
      credentialsRequest: false,
      restrictNumberOfResults: false,
      takeTop: null,
      inheritSettings: false,
      template: null,
      sortResult: false,
      sorting: null,
      collapsed: false,
    })
  }

  @action public onAddFilter(requestType: string, outerCollectionIndex: number): void {
    this[requestType].providers[outerCollectionIndex].filters.push({
      field: "",
      logic: FilterLogic.OR,
      type: FilterType.Contains,
      value: "",
    })
  }
  @action public onAddFieldsReplacement(
    requestType: string,
    outerCollectionIndex: number,
    fieldsCollection: string,
    fieldsCollectionIndex: number
  ): void {
    this[requestType].providers[outerCollectionIndex].fields[fieldsCollection][fieldsCollectionIndex].replacements.push({
      pattern: "",
      replacement: "",
      recursive: false,
    })
  }

  @action public onRemoveFields(
    requestType: string,
    outerCollectionIndex: number,
    fieldsCollection: string,
    fieldsCollectionIndex: number
  ) {
    this[requestType].providers[outerCollectionIndex].fields[fieldsCollection].splice(fieldsCollectionIndex, 1)
  }

  @action public onRemoveFieldsReplacement(
    requestType: string,
    outerCollectionIndex: number,
    fieldsCollection: string,
    fieldsCollectionIndex: number,
    replacementIndex: number
  ) {
    this[requestType].providers[outerCollectionIndex].fields[fieldsCollection][fieldsCollectionIndex].replacements.splice(
      replacementIndex,
      1
    )
  }

  @action public onChangeFieldsCreatable(
    requestType: string,
    outerCollectionIndex: number,
    innerCollectionIndex: number,
    innerCollectionName: string,
    innerCollectionkey: string,
    value: string
  ): void {
    this[requestType].providers[outerCollectionIndex].fields[innerCollectionName][innerCollectionIndex][innerCollectionkey] =
      value
    if (value && innerCollectionName === "root" && !this.rootFields.find((x) => x === value)) {
      this.rootFields.push(value)
    }
    if (value && innerCollectionName === "items" && !this.itemsFields.find((x) => x === value)) {
      this.itemsFields.push(value)
    }
  }

  @action public onChangeFieldsInnerCollection(
    requestType: string,
    outerCollectionIndex: number,
    innerCollectionIndex: number,
    innerCollectionName: string,
    innerCollectionkey: string,
    value: string | number | boolean | Date
  ): void {
    this[requestType].providers[outerCollectionIndex].fields[innerCollectionName][innerCollectionIndex][innerCollectionkey] =
      value
  }

  @action public onChangeFieldsReplacementsKey(
    requestType: string,
    outerCollectionIndex: number,
    innerCollectionIndex: number,
    innerCollectionName: string,
    replacementIndex: number,
    replacementKey: string,
    value: string | number | boolean | Date
  ): void {
    this[requestType].providers[outerCollectionIndex].fields[innerCollectionName][innerCollectionIndex].replacements[
      replacementIndex
    ][replacementKey] = value
  }

  @action public onRemoveProvider(requestType: string, providerIndex: number, confirmText: string) {
    if (confirm(confirmText)) {
      this[requestType].providers.splice(providerIndex, 1)
    }
  }

  @action public async onEditSpider() {
    this.evaluatedData = { result: { root: {}, items: [] }, output: [] }
    const valid = this.validateAll("editDataSourceProvidersRequest", "dataSourceProvidersValidators")
    if (valid) {
      this.saving = true
      const activeProviders =
        this.activeDataSourceTab === ActiveDataSourceTab.DataSourceCode
          ? this.editDataSourceProvidersRequest.providerCode
          : this.editDataSourceProvidersRequest.providers
      try {
        await API.DataSource.updateDataSourceProviders(this.editDataSourceProvidersRequest.id, activeProviders)
        this.dataSourceSuccess = true
        setTimeout(() => {
          this.dataSourceSuccess = false
          this.saving = false
        }, 3000)
      } catch (error) {
        alert(error)
      }
    }
  }

  @action public async onCreateSpider() {
    this.evaluatedData = { result: { root: {}, items: [] }, output: [] }
    const valid = this.validateAll("createDataSourceProvidersRequest", "dataSourceProvidersValidators")
    if (valid) {
      this.saving = true
      const activeProviders =
        this.activeDataSourceTab === ActiveDataSourceTab.DataSourceCode
          ? JSON.parse(this.createDataSourceProvidersRequest.providerCode)
          : this.createDataSourceProvidersRequest.providers
      try {
        await API.DataSource.create({
          name: this.createDataSourceProvidersRequest.name,
          clientId: this.client.id,
          providers: activeProviders,
        })
        this.dataSourceSuccess = true

        setTimeout(() => {
          this.dataSourceSuccess = false
          this.saving = false
          window.location.href = `/${this.client.slug}`
        }, 3000)
      } catch (error) {
        alert(error)
      }
    }
  }

  @action public async onEvaluateDataSource(requestType: string) {
    this.evaluatingSpider = true
    this.evaluatedData = { result: { root: {}, items: [] }, output: [] }
    const valid = this.validateAll(requestType, "dataSourceProvidersValidators")
    if (valid) {
      const newActiveProviders =
        this.activeDataSourceTab === ActiveDataSourceTab.DataSourceCode
          ? JSON.parse(this[requestType].providerCode)
          : this[requestType].providers
      try {
        const response = await API.Spider.evaluateSpider(this.client.id, newActiveProviders)
        this.evaluatedData = response.data
      } catch (error) {
        alert(error)
      }
    }
    this.evaluatingSpider = false
  }

  @action public validateAll(requestType: string, validators: string, nestedValidation?: Object) {
    const valid = nestedValidation
      ? validateAll(nestedValidation, this[requestType][validators])
      : validateAll(this[requestType], this[requestType][validators])

    if (valid.length > 0) {
      valid.forEach((element) => {
        this[requestType].errorModel.onChange(element.key, !element.valid)
      })
      return false
    }

    return true
  }
}
