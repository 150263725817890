import axios from "axios"
import { IReport } from "components/pages/Report/Hooks/useReport"
import { IScheduledReportRequest } from "entities/interfaces"
import { get, put, post, destroy } from "api/restClient"

export interface ICollectionReportRequest {
  campaignIds: string[] | null
  startDate: Date
  endDate: Date
}

interface ICreateReportTokenResponse {
  token: string
}

export const createCollectionReport = (data: ICollectionReportRequest) => post("/Report/token/campaigns", data)

export const updateScheduledReport = (id: string, data: IScheduledReportRequest) => put(`/scheduled-reports/${id}`, data)

export const destroyScheduledReport = (scheduledReportId: string) => destroy(`/scheduled-reports/${scheduledReportId}`)

export const getCampaignReport = (id: string) => get<IReport>(`/Report/campaign/${id}`)

export const getTokenReport = (id: string) => axios.get<IReport>(`${window.tenant.api}/Report/token/${id}`)

export const newAdminReport = (data: ICollectionReportRequest) => post<IReport>(`/Report/campaigns`, data)

export const createReportToken = (data: ICollectionReportRequest) =>
  post<ICreateReportTokenResponse>("/Report/token/campaigns", data)
