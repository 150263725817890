import { post } from "api/restClient"

export interface IBatchCampaignTemplateRequest {
  campaignIds: string[]
  templateId: string
}

export interface IBatchPauseCampaignRequest {
  campaignIds: string[]
  reason: string
}

export interface IBatchCampaignRequest {
  campaignIds: string[]
}

export interface IBatchRequest {
  ids: string[]
}

export const campaignsTemplate = (data: IBatchCampaignTemplateRequest) => post("/Batch/template", data)

export const pauseCampaigns = (data: IBatchPauseCampaignRequest) => post("/Batch/campaigns/pause", data)

export const unpauseCampaigns = (data: IBatchCampaignRequest) => post("/Batch/campaigns/unpause", data)

export const deactivateDataSources = (data: IBatchRequest) => post("/Batch/dataSources/deactivate", data)
