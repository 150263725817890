import * as React from "react"
import { spring, Motion } from "react-motion"
import { tint } from "polished"
import styled, { useTheme } from "styled-components"

const MAX_VALUE = 282

interface DonutChartProps {
  value: number | null
}

const DonutChart = ({ value }: DonutChartProps) => {
  const theme = useTheme()
  const percentage = value ? (value / 100) * MAX_VALUE : 0
  return (
    <DonutSvg viewBox="0 0 100 100">
      <circle
        className="circle background"
        cx="50"
        cy="50"
        r="45"
        fill="none"
        strokeWidth="10"
        strokeMiterlimit="20"
        stroke={tint(0.5, theme.primaryColor)}
      />

      <Motion defaultStyle={{ x: 0 }} style={{ x: spring(percentage, { stiffness: 52, damping: 22 }) }}>
        {(animation) => (
          <circle
            style={{ strokeDasharray: `${animation.x}px ${MAX_VALUE}px` }}
            className={"circle"}
            cx="50"
            cy="50"
            r="45"
            fill="none"
            strokeWidth="10"
            strokeMiterlimit="20"
            stroke={theme.primaryColor}
          />
        )}
      </Motion>
    </DonutSvg>
  )
}

export default DonutChart

const DonutSvg = styled.svg`
  width: 75%;
  position: relative;
  @media (min-width: 960px) {
    width: 100%;
    height: 80%;
  }

  .circle {
    position: absolute;
  }

  .background {
    stroke-dasharray: 282, 282;
  }
`
