import styled from "styled-components"
import { IChannelInfo, ICampaignList, ICampaign, ChannelSettings } from "../../../entities/interfaces"
import { ChannelCampaignState, AdChannelType } from "../../../entities/enums"
import { Tooltip } from "@mui/material"
import { useIntl } from "react-intl"
import FaIcon from "../FaIcon"
import { selectUnit } from "utils/dateHelpers"
import { calculateImpressionCompletion } from "utils/calculations"
import { handleChannelIcon } from "utils/iconHelpers"

interface IChannelProgressCellProps {
  className?: string
  adChannelType: AdChannelType
  campaign: ICampaignList | ICampaign
  isAdmin: boolean
}

const handleChannelStateColor = (channel: IChannelInfo, channelSetting: ChannelSettings) => {
  if (channelSetting && channelSetting.useCustomStaticCreatives) {
    return "color: #800080; a{color:#800080;}"
  }

  if (channel.state === ChannelCampaignState.Active) {
    return "color: #2ed573; a{color:#2ed573;}"
  } else if (channel.state === ChannelCampaignState.Ended) {
    return "color: hsla(210, 17%, 69%, 1); a{color:hsla(210, 17%, 69%, 1);}"
  } else if (channel.state === ChannelCampaignState.Warning) {
    return "color: #ff9a47; a{color:#ff9a47;}"
  } else if (channel.state === ChannelCampaignState.Broken) {
    return "color: #ff4757; a{color:#ff4757;}"
  } else if (channel.warningMessage) {
    return "color: #ff9a47; a{color:#ff9a47;}"
  } else {
    return "color: #191c1f52; "
  }
}

const ChannelContainer = styled.span`
  margin-bottom: 8px;
`

const ChannelIcon = styled.span<{ channel: IChannelInfo, channelSetting: ChannelSettings }>`
  ${({ channel, channelSetting }) => handleChannelStateColor(channel, channelSetting)};
`

const Ago = styled.span`
  color: hsla(210, 17%, 69%, 1);
  font-size: 0.833em;
`

const ChannelProgressCell = ({ className, adChannelType, campaign, isAdmin }: IChannelProgressCellProps) => {
  const { formatMessage, formatRelativeTime, formatNumber } = useIntl()
  const channel = campaign.channels[adChannelType]
  const channelSetting = campaign.channelSettings[adChannelType]

  if (!channel) {
    return null
  }

  const { warningMessage, deliveredImpressions, bookedImpressions, adsLastUpdated, manageLink, totalImpressions } = channel

  const icon = handleChannelIcon(adChannelType)
  const completion = calculateImpressionCompletion(deliveredImpressions, bookedImpressions)
  const { value, unit } = selectUnit(adsLastUpdated ? new Date(adsLastUpdated) : 0)

  return (
    <ChannelContainer className={className}>
      <Tooltip title={warningMessage ? warningMessage : ""}>
        <ChannelIcon channel={channel} channelSetting={channelSetting}>
          {(manageLink &&
            (isAdmin ? (
              <a href={manageLink} target="_blank" rel="noreferrer">
                <FaIcon prefix={icon.prefix} icon={icon.name} />
              </a>
            ) : (
              <FaIcon prefix={icon.prefix} icon={icon.name} />
            ))) || <FaIcon prefix={icon.prefix} icon={icon.name} />}
        </ChannelIcon>
      </Tooltip>

      <Tooltip
        title={formatMessage(
          { id: "hint.channelProgress" },
          {
            impressions: formatNumber(totalImpressions),
            bookedImpressions: formatNumber(bookedImpressions),
            deliveredImpressions: formatNumber(deliveredImpressions),
            completion: completion,
          }
        )}
      >
        <span> {completion}%</span>
      </Tooltip>
      <Ago>
        <br />
        {adsLastUpdated && adsLastUpdated !== "0001-01-01T00:00:00Z" && formatRelativeTime(value, unit, { numeric: "auto" })}
      </Ago>
    </ChannelContainer>
  )
}
export default ChannelProgressCell
