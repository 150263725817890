export const validateEmail = (email: any) => {
  const value = String(email.label || email).toLowerCase()
  return validators.email(value)
}

export const required = (value: string): string | undefined => (value ? undefined : "Is required")

export const isValidJson = (value: string) => {
  try {
    JSON.parse(value)
    return true
  } catch (err) {
    return false
  }
}
export const isValidHttpUrl = (url?: string) => {
  if (!url) {
    return false
  }
  try {
    const newUrl = new URL(url)
    return newUrl.protocol === "http:" || newUrl.protocol === "https:"
  } catch (err) {
    return false
  }
}

export const regex = {
  spaces: /\s+/g,
  email: /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@(([0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i,
  url: /^(https?|ftp):\/\/([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(\/[^\s/$?#].[^\s]*)?$/
};

export const cpmRange = { min: 65, max: 900 }

const validators = {
  email: (value: string) => regex.email.test(value),
  empty: (value: string) => value.replace(regex.spaces, "").length !== 0,
  number: (value: number) => Number.isInteger(Number(value)),
  date: (value: Date) => !isNaN(Number(value)) && value instanceof Date,
  cpm: (value: number) => Number(value) >= cpmRange.min && Number(value) <= cpmRange.max,
  emptyArray: (value: string[]) => value.length !== 0,
  isValidJson: (value: string) => isValidJson(value),
}

export const validate = (value: any, validatorsArr: string[]): boolean => {
  if (!validatorsArr || !validatorsArr.length) {
    return true
  }

  for (let i = 0; i < validatorsArr.length; i++) {
    const validator = validators[validatorsArr[i]]

    if (validator) {
      const valid = validator(value)

      if (!valid) {
        return false
      }
    }
  }

  return true
}

export const validateAll = (model: any, validatorsObject: any): { key: string; valid: boolean }[] => {
  const errors: { key: string; valid: boolean }[] = []
  for (const key in validatorsObject) {
    const valid = validate(model[key], validatorsObject[key])
    if (!valid) {
      errors.push({ key: key, valid: false })
    }
  }
  return errors
}
