import { IPlacement, IVideo, IVideoCapability } from "api/video"
import { ICopyCampaign } from "components/forms/CopyCampaignForm"
import { ICreateCampaignFormTypes } from "components/forms/CreateCampaignForm"
import { AdChannelType, AvailableCategoriesType } from "entities/enums"
import { ChannelSettings, IBudgetPeriodRequest, ICampaign, ISubscriptionSettings, ITargeting } from "entities/interfaces"
import { DefaultSubscriptionSettings } from "./MockedData/campaign"
import { ChannelProfile } from "api/campaign"

export interface ICreateCampaignRequest {
  name: string
  type?: string
  category: AvailableCategoriesType
  clientId: string
  targeting: ITargeting
  cpm: number
  reportRecipients: string[]
  budget: IBudgetPeriodRequest | null
  subscription?: ISubscriptionSettings | null
  isTryAndBuy: boolean
  templateId: string
  dataSourceId: string
  videoPlacements: Record<string, string[]>
  channelSettings: Record<AdChannelType, ChannelSettings>
}

export const mapCopyCampaign = (campaign: ICampaign, copyCampaign: ICopyCampaign): ICreateCampaignRequest => {
  const createCampaign: ICreateCampaignRequest = {
    name: copyCampaign.name,
    type: campaign.type,
    category: campaign.category,
    clientId: campaign.clientId,
    targeting: campaign.targeting,
    cpm: copyCampaign.cpm,
    reportRecipients: campaign.reportRecipients,
    budget: copyCampaign.budget,
    subscription: campaign.budget.subscription?.settings,
    templateId: campaign.templateId,
    dataSourceId: campaign.dataSourceId,
    videoPlacements: campaign.videoPlacements,
    isTryAndBuy: campaign.istryAndBuy,
    channelSettings: campaign.channelSettings,
  }
  return createCampaign
}

export interface ISameForAllData {
  sameForAllAmount: number
  sameForAllChannels: Record<AdChannelType, number>
}

export const mapSameForAllSubscription = <T extends ISameForAllData>(data: T): ISubscriptionSettings => {
  let subscription: ISubscriptionSettings = DefaultSubscriptionSettings
  Object.keys(subscription).forEach((month: string) => {
    subscription[month].amount = data.sameForAllAmount
    subscription[month].channels = data.sameForAllChannels
  })
  return subscription
}

export const mapCreateCampaignForm = (data: ICreateCampaignFormTypes): ICreateCampaignRequest => {
  const createCampaign: ICreateCampaignRequest = {
    name: data.name,
    type: data.type,
    category: data.category,
    clientId: data.clientId,
    targeting: data.targeting,
    cpm: data.cpm,
    reportRecipients: data.reportRecipients,
    budget: data.hasBudgetPeriod ? data.budget : null,
    subscription: data.hasSubscription ? mapSameForAllSubscription(data) : null,
    templateId: data.templateId,
    dataSourceId: data.dataSourceId,
    videoPlacements: data.videoPlacements,
    isTryAndBuy: data.isTryAndBuy,
    channelSettings: data.channelSettings,
  }
  return createCampaign
}

const isEqualOrChildTo = (value: string, category: string) => {
  if (value === category) return true
  return value.startsWith(`${category}-`)
}

export const isSpecialAdCategory = (category: string): boolean => {
  if (!category) return false
  if (isEqualOrChildTo(category, "IAB21")) return true
  if (isEqualOrChildTo(category, "IAB4")) return true
  if (isEqualOrChildTo(category, "IAB11-4")) return true
  if (isEqualOrChildTo(category, "IAB13")) return true
  if (category === "IAB2" || category === "IAB2-3") return true

  return false
}

export const getPreviewPageSize = (maxPageSize: number, items: number): number => {
  if (items < maxPageSize || items % maxPageSize === 0) {
    return maxPageSize
  }

  const fits = Math.ceil(items / maxPageSize)
  return Math.ceil(items / fits)
}

export const filterVideoManifestOnVideoCapability = (manifests: IVideo[], videoCapability: IVideoCapability | null): IVideo[] => {
  return manifests.filter(
    (manifest) =>
      (videoCapability?.minDuration ? videoCapability.minDuration <= manifest.duration : true) &&
      (videoCapability?.maxDuration ? videoCapability.maxDuration >= manifest.duration : true) &&
      videoCapability?.formats.includes(manifest.format)
  )
}

export const filterPlacements = (placements: Record<ChannelProfile, IPlacement[]>): Record<ChannelProfile, IPlacement[]> => {
  const validPlacements = {} as Record<ChannelProfile, IPlacement[]>

  Object.keys(placements).forEach((key: ChannelProfile) => {
    const filteredPlacements = placements[key].filter((placement: IPlacement) => {
      return placement.capability.video !== null
    })

    // Add the key to validPlacements only if there are any placements left after filtering
    if (filteredPlacements.length > 0) {
      validPlacements[key] = filteredPlacements
    }
  })

  return validPlacements
}
