import { IItems } from "entities/interfaces"

export const dictionaryToKeyValueObjectArray = (dictionary: Record<string, string>): { key: string; value: string }[] =>
  dictionary ? Object.keys(dictionary).map((key) => ({ key, value: dictionary[key] })) : []

export const keyValueObjectArrayToDictionary = (objectArray: { key: string; value: string }[]): Record<string, string> =>
  objectArray.reduce((acc, item) => ({ ...acc, [item.key.trim()]: item.value ?? "" }), {})

export const uniques = (arr: any[]) => {
  function filterUniques(value: any, index: number, self: any) {
    return self.indexOf(value) === index
  }
  return arr.filter(filterUniques)
}

export const flattenDictionary = (dic: Record<string, any>) =>
  Object.keys(dic)
    .map((key) => dic[key])
    ?.flat()

export const getRandomItemsFromArray = (arr: any[], numberOfItems: number) =>
  arr.sort(() => 0.5 - Math.random()).slice(0, numberOfItems)

export const getRandomImagesByTags = (images: IItems[], languageCode: string, tags: string[], numberOfItems: number) =>
  images
    .filter((image) =>
      image.tags && image.tags.hasOwnProperty(languageCode)
        ? image.tags[`${languageCode}`].some((tag) => tags.includes(tag))
        : true
    )
    .sort(() => 0.5 - Math.random())
    .slice(0, numberOfItems)

export function sliceArray<T>(array: T[], sliceSize: number): T[][] {
  let result: T[][] = []

  for (let i = 0; i < array.length; i += sliceSize) {
    let slice = array.slice(i, i + sliceSize)
    result.push(slice)
  }

  return result
}
