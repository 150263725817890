import { CreationSourceType, IMoney, INameAndEmailUserModel, IVat } from "entities/interfaces"
import { endOfDateNoUtc, startOfDateNoUtc } from "utils/dateHelpers"
import { post, get } from "api/restClient"
import { OrderCancelReason } from "entities/enums"
import { BillingType } from "api/client"

export interface IOrderPartner {
  id: string
  name: string
  brokerageFee: number
}

export interface IOrderClientView {
  id: string
  tenant: IOrderPartner | null
  reseller: IOrderPartner | null
  group: string
  name: string
  externalId: string
  metadata: Record<string, string>
  slug: string
}

export interface IOrderView {
  id: string
  orderNumber: number
  reference: string
  customReferences: Record<string, string>
  note: string
  orderedBy: INameAndEmailUserModel
  orderedAt: string
  amount: IMoney
  invoiceAmount: IMoney
  cpm: number
  client: IOrderClientView
  vat: IVat
  creationSource: CreationSourceType
  billingType : BillingType
}

export interface ICancelOrderRequest {
  reason: OrderCancelReason
}

export const getAllByDateRange = (startDate: Date, endDate: Date) =>
  get<IOrderView[]>(`/Order/list?start=${startOfDateNoUtc(startDate)}&end=${endOfDateNoUtc(endDate)}`)

export const cancelOrder = (orderId: string, data: ICancelOrderRequest) => post(`/Order/${orderId}/cancel`, data)
