import styled from "styled-components"
import { lighten } from "polished"
import { ButtonType } from "../../../entities/enums"

interface IStyledButtonProps {
  buttonType: ButtonType
  onClick?: (event?: any) => void
  children: string | JSX.Element | object
  disabled?: boolean
  className?: string
  outlined?: boolean
  active?: boolean
  type?: string
  margin?: string
  borderRadius?: string
  form?: string
  custom?: string
}

const StyledButton = ({
  buttonType,
  onClick,
  className,
  disabled,
  children,
  outlined = false,
  active = false,
  type = "button",
  margin = "",
  borderRadius = "3px",
  custom,
  form = undefined,
}: IStyledButtonProps) => (
  <CustomButton
    className={className}
    type={type}
    buttonType={buttonType}
    onClick={onClick}
    disabled={disabled}
    outlined={outlined}
    active={active}
    margin={margin}
    borderRadius={borderRadius}
    form={form}
    custom={custom}
  >
    {children}
  </CustomButton>
)

export default StyledButton

const CustomButton = styled.button`
  padding: 10px 14px;
  min-width: 150px;
  font-size: 14px;
  border-radius: ${(p) => p.borderRadius};
  user-select: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);
  transition: 200ms all;
  ${(p) => p.margin && `margin:${p.margin};`}
  &:focus {
    outline: none;
  }

  ${(p) => handleColorType(p.buttonType, p, p.custom)};

  ${(p) => handleDisabled(p.buttonType)};

  ${(p) =>
    p.outlined &&
    `background-color: transparent;
    color: #2ed573;
    &:hover {
      background-color: #2ed573;
      color: #ffffff;
    }
  `}

  ${(p) =>
    p.active &&
    `background-color: ${p.theme.green};
    color: #ffffff;
    &:hover {
      background-color: ${lighten(0.07, p.theme.green)};
      
    }
  `}
`
const handleDisabled = (buttonType: ButtonType) => {
  switch (buttonType) {
    case ButtonType.Delete:
      return `&:disabled {
      color: darkgray;
      cursor: not-allowed;
      border: grey;
    }`
    default:
      return `  &:disabled {
      color: darkgray;
      background: lightgray;
      cursor: not-allowed;
      border: grey;
    }`
  }
}

const handleColorType = (buttonType: ButtonType, p: any, custom?: string) => {
  switch (buttonType) {
    case ButtonType.Themed:
      return `color: #ffffff; background-color: ${p.theme.primaryColor}; border: 1px solid ${
        p.theme.primaryColor
      }; &:hover {background: ${lighten(0.07, p.theme.primaryColor)};}`
    case ButtonType.Icon:
      return `border-radius:50%; color:${p.theme.primaryColor}; background: transparent;
      box-shadow: none; border: none; &:hover {color: #ffffff; background: ${lighten(
        0.07,
        p.theme.primaryColor
      )};} min-width: unset; font-size: 1em;`
    case ButtonType.Secondary:
      return `color:${p.theme.primaryColor}; background: #ffffff; border: 1px solid ${p.theme.primaryColor};`
    case ButtonType.Success:
      return "box-shadow: none; color: #ffffff; background: #2ed573; border: 1px solid #2ed573; &:hover {background: #2ed5739c;}"
    case ButtonType.Dark:
      return "color: #ffffff; background: #191c1f; border: 1px solid #191c1f; &:hover {background: rgba(25, 28, 31, 0.39);}"
    case ButtonType.Delete:
      return `min-width: unset; box-shadow: none; appearance: none; border:0; background: transparent; color: #000;  &:hover {color: ${p.theme.red};}`
    case ButtonType.Transparent:
      return `box-shadow: none; border:none; background: transparent; color: ${p.theme.primaryColor};  &:hover {background: #f1f2f6;}`
    case ButtonType.Danger:
      return `color: #ffffff; background-color: ${p.theme.red}; border: 1px solid ${p.theme.red}; &:hover {background: ${lighten(
        0.07,
        p.theme.red
      )};}`
    case ButtonType.Custom:
      return `${custom}`
    default:
      return "color: #000; background: #eee;"
  }
}
