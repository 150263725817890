import { useIntl } from "react-intl"
import { IMoney } from "entities/interfaces"

interface IMoneyProps {
  money: IMoney
  className?: string
}

const Money = ({ money, className }: IMoneyProps) => {
  const { formatNumber } = useIntl()
  return (
    <span className={className}>
      {formatNumber(Math.round(money.value / 10) * 10, {
        style: "currency",
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
        currency: money.currencyCode,
      })}
    </span>
  )
}

export default Money
