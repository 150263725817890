import styled from "styled-components"
import { useIntl } from "react-intl"
import StyledButton from "components/common/Buttons/StyledButton"
import { ButtonType } from "entities/enums"
import { useForm } from "react-hook-form"
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"
import ControlTextField from "components/fields/ControlTextField"
import { ILoginCredentials } from "api/token"

interface ILoginFormProps {
  disabled: boolean
  handleSignIn: (data: ILoginCredentials) => void
}

const loginSchema: yup.SchemaOf<ILoginCredentials> = yup.object().shape({
  email: yup.string().email("error.email").required("error.required"),
  password: yup.string().required("error.required"),
})

const LoginForm = ({ disabled, handleSignIn }: ILoginFormProps) => {
  const { formatMessage } = useIntl()
  const { control, handleSubmit } = useForm({
    defaultValues: {
      email: "",
      password: "",
    },
    resolver: yupResolver(loginSchema),
  })

  return (
    <LoginFormContainer onSubmit={handleSubmit(handleSignIn)}>
      <ControlTextField
        name="email"
        type="email"
        control={control}
        disabled={disabled}
        label={formatMessage({ id: "common.email" })}
      />
      <ControlTextField
        name="password"
        type="password"
        control={control}
        disabled={disabled}
        label={formatMessage({ id: "common.password" })}
      />
      <StyledButton type="submit" buttonType={ButtonType.Themed} disabled={disabled}>
        {formatMessage({ id: "common.login" })}
      </StyledButton>
    </LoginFormContainer>
  )
}

export default LoginForm

const LoginFormContainer = styled.form`
  display: grid;
  gap: 10px;
  padding: 5em;
`
