import {
  endOfHour,
  addHours,
  addMinutes,
  addDays,
  endOfDay,
  format,
  isPast,
  nextSunday,
  nextMonday,
  nextTuesday,
  nextWednesday,
  nextThursday,
  nextFriday,
  nextSaturday,
} from "date-fns"
import { EndDateSetting } from "entities/enums"

export const startOfDateNoUtc = (date: Date) => `${format(date, "yyyy-MM-dd")}T00:00:00.000Z`
export const endOfDateNoUtc = (date: Date) => `${format(date, "yyyy-MM-dd")}T23:59:59.999Z`

export const dateInPast = (date: any): any => {
  if (typeof date === "number") {
    return date < Date.now()
  }
  return new Date(date).getTime() < Date.now()
}

export const addHoursClean = (date: Date, hours: number): Date => {
  return addMinutes(endOfHour(addHours(date, hours)), 1)
}

export const createEndDate = (days: number): Date => {
  return addDays(addMinutes(endOfDay(new Date()), 1), days)
}

export const getDateByEndDateSetting = (endDateSetting: EndDateSetting): Date => {
  const now = new Date()
  switch (endDateSetting) {
    case EndDateSetting.Monday:
      return nextMonday(now)
    case EndDateSetting.Tuesday:
      return nextTuesday(now)
    case EndDateSetting.Wednesday:
      return nextWednesday(now)
    case EndDateSetting.Thursday:
      return nextThursday(now)
    case EndDateSetting.Friday:
      return nextFriday(now)
    case EndDateSetting.Saturday:
      return nextSaturday(now)
    case EndDateSetting.Sunday:
      return nextSunday(now)
    default:
      return nextSunday(now)
  }
}

export const handleSetDefaultEndDate = (endDate: Date, endDateSetting: EndDateSetting): Date => {
  if (endDateSetting !== EndDateSetting.None) {
    return getDateByEndDateSetting(endDateSetting)
  }
  if (!endDate || isPast(endDate)) {
    return createEndDate(14)
  }
  return endDate
}

export type Unit = "second" | "minute" | "hour" | "day" | "week" | "month" | "quarter" | "year"

const MS_PER_SECOND = 1e3
const SECS_PER_MIN = 60
const SECS_PER_HOUR = SECS_PER_MIN * 60
const SECS_PER_DAY = SECS_PER_HOUR * 24
const SECS_PER_WEEK = SECS_PER_DAY * 7
const APPROX_DAYS_PER_MONTH = 30.4

export function selectUnit(
  from: Date | number,
  to: Date | number = Date.now(),
  thresholds: Partial<Thresholds> = {}
): { value: number; unit: Unit } {
  const resolvedThresholds: Thresholds = {
    ...DEFAULT_THRESHOLDS,
    ...(thresholds || {}),
  }
  const secs = (+from - +to) / MS_PER_SECOND
  if (Math.abs(secs) < resolvedThresholds.second) {
    return {
      value: Math.round(secs),
      unit: "second",
    }
  }
  const mins = secs / SECS_PER_MIN
  if (Math.abs(mins) < resolvedThresholds.minute) {
    return {
      value: Math.round(mins),
      unit: "minute",
    }
  }
  const hours = secs / SECS_PER_HOUR
  if (Math.abs(hours) < resolvedThresholds.hour) {
    return {
      value: Math.round(hours),
      unit: "hour",
    }
  }

  const days = secs / SECS_PER_DAY
  if (Math.abs(days) < resolvedThresholds.day) {
    return {
      value: Math.round(days),
      unit: "day",
    }
  }

  const weeks = secs / SECS_PER_WEEK
  if (Math.abs(weeks) < resolvedThresholds.week) {
    return {
      value: Math.round(weeks),
      unit: "week",
    }
  }

  const months = secs / SECS_PER_DAY / APPROX_DAYS_PER_MONTH

  if (Math.abs(months) < resolvedThresholds.month) {
    return {
      value: Math.round(months),
      unit: "month",
    }
  }

  const fromDate = new Date(from)
  const toDate = new Date(to)

  const years = fromDate.getFullYear() - toDate.getFullYear()
  if (Math.round(Math.abs(years)) > 0) {
    return {
      value: Math.round(years),
      unit: "year",
    }
  }

  return {
    value: Math.round(weeks),
    unit: "week",
  }
}

type Thresholds = Record<"second" | "minute" | "hour" | "day" | "week" | "month", number>

export const DEFAULT_THRESHOLDS: Thresholds = {
  second: 45, // seconds to minute
  minute: 45, // minutes to hour
  hour: 22, // hour to day
  day: 5, // day to week
  week: 3, // week to month
  month: 9, // month to year
}
