import { useLocation } from "react-router-dom"
import queryString from "query-string"
import { refreshTokenExpiresIn } from "utils/tokenHelpers"
import { decodeTokenToUser } from "utils/user_tenant_helpers"
import { useSignIn } from "react-auth-kit"
import jwtDecode, { JwtPayload } from "jwt-decode"
import { useCallback, useEffect } from "react"

interface IIntegrationAuthProps {
  children: JSX.Element
}

const IntegrationAuth = ({ children }: IIntegrationAuthProps) => {
  const { search } = useLocation()
  const query = queryString.parse(search)
  const signIn = useSignIn()
  const handleSignIn = useCallback(
    async (query: any) => {
      const user = decodeTokenToUser(query.token)
      signIn({
        token: query.token,
        expiresIn: jwtDecode<JwtPayload>(query.token).exp!,
        tokenType: "Bearer",
        authState: user,
        refreshToken: query.refresh_token,
        refreshTokenExpireIn: refreshTokenExpiresIn,
      })
    },
    [signIn]
  )

  useEffect(() => {
    handleSignIn(query)
    // lint disable cause infinite loop
  }, []) // eslint-disable-line

  return children
}

export default IntegrationAuth
